import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, HostListener } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { DataService, IEntries, IActions, IFilters, IQuery } from '../../services/data.service';
import { ConfigService } from 'src/app/services/config.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { FormBuilder, Validators, Form, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PrintDialogComponent } from '../print-dialog/print-dialog.component';
import { ConfimDialogComponent } from '../confim-dialog/confim-dialog.component';
import { ImageDialogComponent } from '../image-dialog/image-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PrintersService } from 'src/app/services/printers.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0', display: 'none' })),
      state('expanded', style({ height: '*', padding: '0.2rem 0 1rem 0' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class ListComponent implements OnInit, AfterViewInit, OnDestroy {
  isLoadingResults: boolean;
  resultsLength: number;
  displayedColumns: string[];
  data: IEntries[];
  dataSource: MatTableDataSource<IEntries>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  flatColumns: string[];
  colDictionary = {};
  key: string;
  actions: IActions[];
  rolesTranslate: { [key: string]: string };
  expandedElement: IEntries;
  detailRow: string;
  header: any;
  prop: any;
  filterForm: FormGroup;
  filters: IFilters[];
  page: number;
  perPage: number;
  pageEvent: PageEvent;
  query: { key: any; value: any; };
  count: number;
  title: string;
  routingData: Params;
  hasNext: boolean;
  hasBack: boolean;
  preheader: { render?: { forms?: { actions: any[]; fields: any[]; key: string; title: string; }[]; }; };
  printer$: Subscription = Subscription.EMPTY;
  printersForm: FormGroup;
  printers: any[];
  selectedStore$: Subscription;

  constructor(
    private dataService: DataService,
    private configService: ConfigService,
    private printerStatus: PrintersService,
    private route: ActivatedRoute,
    private router: Router,
    public fb: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.detailRow = '';
  }

  ngOnInit(): void {
    this.route.data.subscribe(data => {
      this.routingData = data;
    })
    this.resultsLength = 0;
    this.isLoadingResults = true
    this.dataSource = new MatTableDataSource();

    this.route.paramMap.subscribe(params => {
      this.key = params.get('key');
      this.resetPagination();
      this.getList();
    });

    this.selectedStore$ =  this.configService.selectedStoreSubject
      .subscribe(store => this.getList());
    this.resetPagination();
    this.count = 0;
    this.setForm();
    this.setPrinters();
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    if (!this.printer$.closed) {
      this.printer$.unsubscribe();
    }
    if (!this.selectedStore$.closed) {
      this.selectedStore$.unsubscribe();
    }
  }

  setPrinters(): void {
    this.printersForm = this.fb.group({
      printer: ['', Validators.compose([
        Validators.required
      ])]
    });
    this.printer$ = this.printerStatus
      .printerStatu$
      .subscribe((status: any[]) => {
        if (status?.length > 0) {
          let prev = this.printersForm.get('printer').value || '';
          this.printers = [];
          status
            .map(p => {
              this.printers.push(p);
            });
          if (prev === '') {
            prev = this.printers[0].id;
          }
          this.printersForm.get('printer').patchValue(prev, { emitEvent: false });
        }
      });
  }

  get status(): any {
    let status = {
      semaphore: 'red',
      name: 'cargando...',
    }
    if (this.printers?.length > 0) {
      const printer = this.printers.find(p => p.id === this.printersForm.get('printer').value);
      if (printer) {
        status = printer
      }
    }
    return status;
  }

  setForm() {
    const [first] = this.filters || [];
    const { key = '' } = first || {};
    this.filterForm = this.fb.group({
      key: [key, Validators.compose([
        Validators.required
      ])],
      value: ['', Validators.compose([
        Validators.required,
        Validators.minLength(2)
      ])]
    });
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  customTrackBy(index, item) {
    return item.key;
  }

  getList() {
    this.resultsLength = 0;
    this.isLoadingResults = true
    this.filters = undefined;
    this.displayedColumns = [];
    this.dataSource.data = [];
    const query = [
      {
        key: 'storageBinWarehouse',
        value: this.configService.selectedStorage.id
      }
    ];
    if (!!this.routingData[this.key]) {
      query.push(this.routingData[this.key]);
    }
    const pagination = { page: this.page, perPage: this.perPage };
    this.title = '';
    this.dataService.getList(this.key, pagination, query)
      .subscribe(
        ({ body, header: preheader }) => {
          const { entries, hasNext, render: { filters, columns, _actions = [], header, pagination, title } } = body;
          this.page = pagination?.page || 1;
          this.perPage = pagination?.perPage || 40;
          this.hasBack = this.page > 1;
          this.hasNext = hasNext;
          this.preheader = preheader;

          this.displayedColumns = columns.map(col => {
            this.colDictionary[col.key] = col.title;
            return col.key
          });
          if (filters && filters.length > 0) {
            this.filters = filters;
          }
          if (_actions.length > 0) {
            this.displayedColumns.push('listActions');
            this.actions = _actions;
          }
          this.dataSource.data = entries;
          this.resultsLength = pagination?.count;
          this.header = header;
          this.title = title;
        },
        err => {
          console.error(err);
          this.isLoadingResults = false;
        },
        () => this.isLoadingResults = false
      );
  }

  action(action, id) {
    console.log('action', action, 'id', id);
  }

  scannerPreview(element) {
    const imageArr = element.image.split('/');
    const image = 'https://localhost:4000/images/' + imageArr[imageArr.length - 1];
    this.dataService.get(image).subscribe((data) => {
      console.log('no debería funcionar este request...', data);
    }, (err) => {
      if (err.status === 200) {
        this.dialog.open(ImageDialogComponent, { data: { image } });
      } else {
        this.snackBar.open('La imagen se puede previsualizar sólo en el computador donde se digitalizó');
      }
    });
  }

  goPost(element, action) {
    let buttons;
    let message;

    switch (action.code) {
      case 'printManifest':
        if (!this.status.id) {
          this.snackBar.open('No hay una impresora seleccionada o disponible');
          return;
        }
        if (!this.status.status.ready) {
          this.snackBar.open('La impresora seleccionada no esta disponible');
          return;
        }
        const printDialogRef = this.dialog
          .open(PrintDialogComponent, { data: element });
        printDialogRef.afterClosed()
          .subscribe(result => {
            if (!result) return;
            if (!this.status.id) return;
            result = { ...result, storageBinLocation: result.storageBin, printer: this.status.id };
            this.dataService.post(action.path, result)
              .subscribe(
                (data) => {
                  if (data?.body?.render?.message?.status === 'OK') {
                    this.snackBar.open('Impresión en cola');
                    this.getList();
                  } else {
                    this.snackBar.open('Impresora no encontrada, por favor comunicarse con helpdesk');
                    this.getList();
                  }
                },
                () => this.snackBar.open('Etiqueta(s) no impresa(s)')
              );
          });
        break;
      case 'deleteManifest':
        buttons = { go: 'Eliminar' };
        message = `¿Esta seguro de eliminar ${element.description}?`;
        const deleteDialogRef = this.dialog
          .open(ConfimDialogComponent, { data: { element, buttons, message } });

        deleteDialogRef.afterClosed()
          .subscribe(result => {
            if (!result) return;
            this.dataService.delete(action.path)
              .subscribe(
                () => {
                  this.snackBar.open('Solicitud enviada');
                  this.getList();
                },
                () => this.snackBar.open('Etiqueta(s) no eliminada(s)')
              )
          });
        break;
      case 'createManifest':
        buttons = { go: 'Activar' };
        message = `Activar el manifiesto de ${element.description}?`;
        const createManifestDialogRef = this.dialog
          .open(ConfimDialogComponent, { data: { element, buttons, message } });

        createManifestDialogRef.afterClosed()
          .subscribe(result => {
            if (!result) return;
            delete element.storageBinLocation
            this.dataService.post(action.path, element)
              .subscribe(
                () => {
                  this.snackBar.open('Solicitud enviada');
                  this.getList();
                },
                () => this.snackBar.open('No fue posible recrear el manifiesto')
              )
          });
        break;
      case 'editUser':
        this.router.navigateByUrl(action.path);
        break;
      case 'deleteUser':
        buttons = { go: 'Eliminar' };
        message = `Eliminar el usuario ${element.name}?`;
        const deleteUserDialogRef = this.dialog
          .open(ConfimDialogComponent, { data: { element, buttons, message } });

        deleteUserDialogRef.afterClosed()
          .subscribe(result => {
            if (!result) return;
            this.dataService.delete(action.path)
              .subscribe(
                () => {
                  this.snackBar.open('Usuario eliminado');
                  this.getList();
                },
                () => this.snackBar.open('No fue posible eliminar el usuario')
              )
          });
        break;

      default:
        break;
    }
  }

  goLoad(element, action) {
    switch (action.code) {
      case 'comment-cart':
        this.prop = 2;
        this.expand(element);
        break;

      default:
        this.dataService.load(action.url, action.data)
          .subscribe(
            respone => console.log,
            err => console.error,
            () => this.getList()
          );
        break;
    }
  }

  expand(element) {
    this.expandedElement = undefined;
    this.detailRow = '';
    if (this.prop > 0) {
      this.prop = this.prop - 1;
      this.expandedElement = element;
      this.detailRow = 'comments';
    } else {
      if (element.hasOwnProperty('products')) {
        this.expandedElement = element;
        this.detailRow = 'orders';
      }
    }
  }

  doPagination(event: number) {
    this.page = event;
    // this.perPage = event.pageSize;
    this.getList()
  }

  dofilter() {
    if (this.filterForm.valid) {
      const { key, value } = this.filterForm.value;
      this.query = {
        key: key,
        value: value
      };
      this.getList();
    }
  }

  getQuery(): IQuery {
    if (this.query?.key) {
      return { ...this.query };
    }
    return undefined
  }

  resetPagination() {
    this.page = 1;
    this.perPage = 10;
  }

  get listTitle(): string {
    return this.title;
  }

  get headerActions(): any[] {
    if (this.preheader?.render?.forms?.length > 0) {
      return this.preheader?.render?.forms[0]?.actions
    }
    return [];
  }
}
