<section>
  <div class="contenedor">
    <div class="contenedor_img">
      <img src="/assets/load.png">
    </div>
    <div class="contenedor_formulario">
      <div class="smartbot">
        <span >SmartBot</span>
      </div>
      <form [formGroup]="loginForm">
        <div>
          <span class="mat-headline">Inicie sesión en su cuenta</span>
        </div>
        <span class="mat-h4">ingrese su email y contraseña para continuar</span>
        <div class="row">
          <div class="col-12 col-md-12">
            <mat-form-field appearance="fill">
              <mat-label>email</mat-label>
              <input matInput placeholder="ej. example@mail.com" formControlName="id" autocomplete="off" />
              <mat-error *ngIf="loginForm.get('id').hasError('email') && !loginForm.get('id').hasError('required')">
                Ingrese un email valido
              </mat-error>
              <mat-error *ngIf="loginForm.get('id').hasError('required')">
                email es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-12">
            <mat-form-field appearance="fill">
              <mat-label>contraseña</mat-label>
              <input type="password" matInput formControlName="password" autocomplete="off" />
              <mat-error *ngIf="loginForm.get('password').hasError('required')">
                contraseña es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 loginbuttons">
            <button mat-flat-button color="primary" (click)="login()" [disabled]="loginForm.invalid">
              Inicia sesión
            </button>

            <a class="forgotlink" (click)="goReset($event)">
              Olvidé mi contraseña
            </a>
          </div>

        </div>
      </form>
    </div>
  </div>
</section>
<!-- <div class="login-page">
  <div class="form">
    <img class="img-fluid" src="/assets/cross-control-sidebar-gray.png">
    <h3>Hello, welcome to {{title}}</h3>
    <div class="container">
      <form [formGroup]="loginForm">
        <div class="row">
          <div class="col-12 col-md-12">
            <mat-form-field appearance="fill">
              <mat-label>Mail</mat-label>
              <input matInput placeholder="daryl@dixon.com" formControlName="mail" autocomplete="off" />
              <mat-error *ngIf="loginForm.get('mail').hasError('email') && !loginForm.get('mail').hasError('required')">
                Ingrese un email valido
              </mat-error>
              <mat-error *ngIf="loginForm.get('mail').hasError('required')">
                Email es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-12">
            <mat-form-field appearance="fill">
              <mat-label>Password</mat-label>
              <input type="password" matInput placeholder="" formControlName="password" autocomplete="off" />
              <mat-error *ngIf="loginForm.get('password').hasError('required')">
                Password es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </form>
    </div>
    <button mat-stroked-button (click)="login()" [disabled]="loginForm.invalid">
      Inicia sesión
    </button>
  </div>
</div> -->
