import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

type DialogData = any;

@Component({
  selector: 'confim-dialog',
  templateUrl: './confim-dialog.component.html',
  styleUrls: ['./confim-dialog.component.scss']
})
export class ConfimDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ConfimDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get message(): string {
    return this.data?.message || '¿Esta seguro?';
  }

  get go(): string {
    return this.data?.buttons?.go || 'Aceptar';
  }
  
  get cancel(): string {
    return this.data?.buttons?.cancel || 'Cancelar';
  }
}
