import { Component, OnInit } from '@angular/core';
import { DataService, IChartData } from '../../services/data.service';
import { EcomerceService, IProfit } from '../../services/ecomerce.service';
import { Storage } from '../../classes/store';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { sub, format } from 'date-fns'

@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  activeSidebar: any;
  activeHeader: any;
  realtimeChartData: any;
  profitData: IProfit[];
  lineChartData: IChartData;
  advancedPieData: IChartData;
  storage: Storage;
  byUser: any;
  general: any;
  barLabels: string[];
  barData: { data: number[], label: string }[];
  endDate: string;
  startDate: string;
  dateForm: FormGroup;

  constructor(
    private dataService: DataService,
    public router: Router,
    public fb: FormBuilder,
    public dialog: MatDialog,
  ) {
    this.barLabels = ['Archivo', 'Escaner', 'Manual', 'Reimpresion'];
  }

  ngOnInit(): void {
    const today = new Date();
    this.endDate = format(today, 'yyyy-MM-dd');
    this.startDate = format(sub(today, { years: 1 }), 'yyyy-MM-dd');
    this.load();
    this.setForm();
  }

  setForm() {
    this.dateForm = this.fb.group({
      endDate: [this.endDate, Validators.required],
      startDate: [this.startDate, Validators.required],
    });
    this.dateForm
      .valueChanges
      .subscribe(val => {
        try {
          const endDate = format(new Date(val.endDate), 'yyyy-MM-dd')
          const startDate = format(new Date(val.startDate), 'yyyy-MM-dd')
          this.dataService
          .get('/dashboard/user', { endDate, startDate })
          .subscribe(data => {
            this.byUser = data;
            this.init();
          });
        } catch (error) {
          
        }
      })
  }

  load() {
    this.dataService.get('/dashboard/general')
      .subscribe(data => {
        this.general = data;
      });
    this.dataService
      .get('/dashboard/user', { endDate: this.endDate, startDate: this.startDate })
      .subscribe(data => {
        this.byUser = data;
        this.init();
      });
  }


  init() {
    this.barData = this.byUser.userData.map(user => {
      const label = user.name;
      const data = [
        user.byArchivo || 0,
        user.byEscaner || 0,
        user.byManual || 0,
        user.byReimpresion || 0,
      ];
      return { data, label };
    });
  }

}
