import { Menu, Storage } from './store';

interface IEnvironment {
  label: string;
  options: {
    id: string;
    name: string
  }[]
}

export interface IUser {
  id: string;
  name: string;
  role: string[];
  menu: Menu[];
  storages: Storage[];
  environment?: IEnvironment;
}

export interface IRespUser extends IUser {
  token: string;
}
export class User implements IUser {
  public id: string;
  public name: string;
  public role: string[];
  public menu: Menu[];
  public storagesName: string;
  public storages: Storage[];

  constructor(usr?: IUser) {
    if (!usr?.id) { return; }
    this.id = usr.id;
    this.name = usr.name;
    this.role = usr.role;
    this.menu = usr.menu;
    this.storagesName = usr.environment.label;
    this.storages = usr.environment.options.map(sto => new Storage(sto));
  }
}
