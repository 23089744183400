import { Pipe, PipeTransform } from '@angular/core';
import { IField } from '../classes/expression';

interface IForms {
  key: string;
  title: string;
  fields: IField[];
};

@Pipe({
  name: 'formTitle'
})
export class FormTitlePipe implements PipeTransform {
  defaultMessage = '404 Title not found';
  transform(key: string, forms: IForms[]): string {
    if (forms.length === 0) {
      return this.defaultMessage;
    }
    const form = forms.find(ff => ff.key === key);
    return form?.title || this.defaultMessage;
  }

}
