<div class="container-fluid">
  <div class="row">
    <h2>Datos ingresados:</h2>
  </div>
  <hr>
  <div class="row justify-content-start">
    <ng-container *ngFor="let for of detail.form; let ii = index">
      <div class="col-12 col-md-6">
        <div class="row px-0">
          <div class="col-6">
            <strong>{{for.label}}:</strong>
          </div>
          <div class="col-6">
            <p>{{for.value}}</p>
          </div>
        </div>
      </div>
      <div class="w-md-100" *ngIf="(ii+1)%2 === 0"></div>
    </ng-container>
  </div>
  <div class="row">
    <h2>Productos:</h2>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <table mat-table class="ww-100" [dataSource]="detail.products">
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let element"> {{element.title}} </td>
        </ng-container>
        <ng-container matColumnDef="sku">
          <th mat-header-cell *matHeaderCellDef> SKU </th>
          <td mat-cell *matCellDef="let element"> {{element.sku}} </td>
        </ng-container>
        <ng-container matColumnDef="price">
          <th mat-header-cell *matHeaderCellDef> Precio </th>
          <td mat-cell *matCellDef="let element"> {{element.price}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="['title', 'sku', 'price']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['title', 'sku', 'price'];"></tr>
      </table>
    </div>
  </div>
  <div class="row justify-content-start">
    <h2>Pagos:</h2>
  </div>
  <hr>
  <div class="row">
    <div class="col-12">
      <table mat-table class="ww-100" [dataSource]="detail.payments">
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Estado </th>
          <td mat-cell *matCellDef="let element">
            <mat-icon class="fail" *ngIf="element.status === 'Fallido'">error_outline</mat-icon>
            <mat-icon class="check" *ngIf="element.status === 'OK'">check_circle_outline</mat-icon>
            <mat-icon class="load" *ngIf="element.status === 'Pendiente'">schedule</mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="amount">
          <th mat-header-cell *matHeaderCellDef> Monto </th>
          <td mat-cell *matCellDef="let element"> {{element.amount}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="['status', 'amount']"></tr>
        <tr mat-row *matRowDef="let row; columns: ['status', 'amount'];"></tr>
      </table>
    </div>
  </div>
</div>
