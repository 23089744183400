import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ConfigService } from 'src/app/services/config.service';
import { TITLE, LOGIN } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  activeSidebar: any;
  activeHeader: any;
  title = TITLE;
  loginForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private auths: AuthService,
    private config: ConfigService,
    private router: Router,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void { 
    this.setForm();
  }

  setForm(): void {
    this.loginForm = this.fb.group({
      id: [LOGIN.email, Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(2)
      ])],
      password: [LOGIN.password, Validators.compose([
        Validators.required
      ])]
    })
  }

  login(): void {
    if(this.loginForm.valid) {
      this.auths.login(this.loginForm.value)
        .subscribe(
          () => {
          },
          err => {
            const ok = this.snackBar.open('Error, hubo un problema comuniquese con helpdesk', 'OK', {
              duration: 1000 * 60 * 30
            });
            ok.afterDismissed().subscribe(() => this.router.navigate(['login']));
          }
        )
    }
  }

  goReset(event): void {
    event.preventDefault();
    this.router.navigate(['i-forgot-password']);
  }
}
