<h1 mat-dialog-title>Imprimir {{(isScanner)? 'etiqueta escaneada' :''}}</h1>
<div mat-dialog-content>
  <p>{{data.stockCode}}</p>
  <p>{{data.description}}</p>
  <form [formGroup]="labelForm">
    <mat-form-field appearance="fill">
      <mat-label>Monto a imprimir</mat-label>
      <input matInput type="number" [formControlName]="'printAmount'">
      <mat-error *ngIf="labelForm.get('printAmount').hasError('required')">
        El numero de etiquetas a imprimir es <strong>requerido</strong>
      </mat-error>
      <mat-error *ngIf="labelForm.get('printAmount').hasError('pattern')">
        Solo se permiten <strong>números</strong>
      </mat-error>
      <mat-error *ngIf="labelForm.get('printAmount').hasError('min')">
        Solo se permiten números <strong>mayores a 0</strong>
      </mat-error>
    </mat-form-field>
    <ng-container *ngIf="isNotScanner">
      <mat-form-field appearance="fill">
        <mat-label>Ubicación</mat-label>
        <p matPrefix>{{data.storageBinWarehouse}}</p>
        <input matInput [formControlName]="'storageBin'">
        <mat-error *ngIf="labelForm.get('storageBin').hasError('required')">
          La ubicación <strong>requerida</strong>
        </mat-error>
        <mat-error *ngIf="labelForm.get('storageBin').hasError('minlength')">
          Largo <strong>mínimo 10</strong>
        </mat-error>
        <mat-error *ngIf="labelForm.get('storageBin').hasError('maxlength')">
          Largo <strong>máximo 10</strong>
        </mat-error>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Orden de compra</mat-label>
        <input matInput [formControlName]="'purchaseOrder'">
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Estado</mat-label>
        <mat-select [formControlName]="'valuationType'">
          <mat-option [value]="option" *ngFor="let option of options">
            {{ option }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </form>
</div>
<div mat-dialog-actions>
  <button mat-flat-button color="warn" (click)="onNoClick()">Cancelar</button>
  <button mat-flat-button color="primary" [mat-dialog-close]="dataDialog" [disabled]="labelForm.invalid"
    cdkFocusInitial>Imprimir</button>
</div>
