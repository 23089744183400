export interface IMenu {
  label: string;
  route: string[];
  icon: string;
}

export class Menu implements IMenu {
  public label: string;
  public route: string[];
  public icon: string;
  constructor(mnu: IMenu) {
    this.label = mnu.label;
    this.route = mnu.route;
    this.icon = mnu.icon;
  }
}

export interface ITheming {
  logo: string;
}

interface IStorage {
  name: string;
  id: string;
}

export class Storage implements IStorage {
  name: string;
  id: string;
  constructor(sto: IStorage) {
    this.name = sto.name.toLocaleUpperCase();
    this.id = sto.id;
  }
}
