import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FORM_KEY, SHADOW_INPUT, SHOWED_INPUT } from 'src/app/config/uploadMaster.contract.constant';
import { DataService } from 'src/app/services/data.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { FormBaseComponent } from '../form-base/form-base.component';

@Component({
  selector: 'form-master',
  templateUrl: './form-master.component.html',
  styleUrls: ['./form-master.component.scss']
})
export class FormMasterComponent extends FormBaseComponent implements OnInit {
  progress: number;
  _message: string;
  file: File;
  _loading: boolean;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public dataService: DataService,
    private uploadService: FileUploadService,
  ) {
    super(fb, dialog);
  }

  ngOnInit(): void {
    this._loading = false;
    this.dataService.get(this.router.url)
      .subscribe(data => {
        this.config = data;
        this.getForm();
      });
  }

  fileHandler(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get(FORM_KEY).patchValue({
        [SHADOW_INPUT]: file
      });
    }
  }

  doget(path) {
    this.dataService.get(path)
      .subscribe((data) => { console.log('===>🚀 data 🚀', data); });
  }

  upload(path): void {
    this.progress = 0;
    this._message = '';
    this._loading = true;
    if (this.form.valid) {

      const file: File = this.form.get(FORM_KEY).get(SHADOW_INPUT).value;

      console.log('===>🚀 this.form.get(FORM_KEY).value 🚀', this.form.get(FORM_KEY).value);
      const formData: FormData = new FormData();
      formData.append('file', file);
      Object.entries(this.form.get(FORM_KEY).value)
        .filter(([key]) => ![SHADOW_INPUT, SHOWED_INPUT].includes(key))
        .forEach(([key, value]) => {
          formData.append(key, value as any);
        });

      this.uploadService
        .upload(path, formData)
        .subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.progress = undefined;
              this._message = event.body.message;
              console.log('===>🚀 event 🚀', event);
              this.form.reset();
              this._loading = false;
            }
          },
          (err: any) => {
            console.log(err);
            this.progress = 0;
            if (err.error && err.error.message) {
              this._message = err.error.message;
            } else {
              this._message = 'No se pudo cargar el archivo!';
            }
            this._loading = false;
          });
    }
  }

  get loading(): boolean {
    return this._loading;
  }

  get message(): string {
    return this._message;
  }

  get buttons(): any[] {
    if (!this.config?.header?.render?.forms?.some(f => !!f.actions[0].path)) {
      return [];
    }
    return this.config?.header?.render?.forms.map(f => f.actions).flat();
  }

  get bussy(): boolean {
    return (this.config?.header?.render?.message?.status || '').toUpperCase() !== 'OK'
  }

  get bussyMessage(): string {
    let message = ''
    if(this.bussy) {
      message = this.config?.header?.render?.message?.label;
    }
    return message;
  }


  private getForm() {
    const form = this.config?.header?.render?.forms;
    if (form) {
      this.forms = form;
    }
    this.values = {};
    this.setForm();
  }

}
