<div class="container">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="form">
        <mat-card>
          <form [formGroup]="form">
            <mat-card-content>
              <ng-container *ngFor="let key of ckeys">
                <mat-card-title>{{key | formTitle: forms}}</mat-card-title>
                <dynamic-form [data]="forms" [controlName]="key" (uploadEvent)="fileHandler($event)"></dynamic-form>
              </ng-container>
            </mat-card-content>
          </form>
          <mat-card-actions>
            <mat-spinner *ngIf="loading else showbuttons"></mat-spinner>
            <ng-template #showbuttons>
              <div class="row">
                <ng-container *ngFor="let but of buttons">
                  <div class="col">
                    <ng-container *ngIf="but.action === 'uploadFile' else getaction">
                      <button mat-flat-button color="primary" [disabled]="form.invalid"
                        (click)="upload(but.path)">{{but.label}}</button>
                    </ng-container>
                    <ng-template #getaction>
                      <button mat-flat-button color="primary" [disabled]="form.invalid"
                        (click)="dopost(but.path)">{{but.label}}</button>
                    </ng-template>
                  </div>

                  <div class="col red d-flex h-100">
                    <ng-container *ngIf="!!updatedAt; else noUpdate">
                      <span class="w-100 text-end justify-content-center align-self-center">
                        Última actualización realizada: {{ updatedAt | date:'dd/MM/yyyy HH:mm' }}
                      </span>
                    </ng-container>
                    <ng-template #noUpdate>
                      'No hay resultados cargados'
                    </ng-template>
                  </div>
                </ng-container>
              </div>
            </ng-template>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="dataSource?.data?.length > 0">
    <div class="row">
      <div class="col-12">
        <mat-card>
          <mat-card-content>
            <div class="history-title">
              <mat-card-title>
                {{titleLoad}}
              </mat-card-title>
              <mat-card-subtitle>
                {{docMessage.label}}
              </mat-card-subtitle>
            </div>
            <div class="col-12">
              <ng-container *ngIf="dataSource?.data?.length > 0">
                <table class="ww-100" mat-table [dataSource]="dataSource" multiTemplateDataRows>
                  <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
                    <ng-container class="d-flex" [ngSwitch]="col">

                      <ng-container *ngSwitchCase="'published'">
                        <th mat-header-cell *matHeaderCellDef>Publicado</th>
                        <td mat-cell *matCellDef="let row">
                          <span class="bind {{row[col]}}">{{row[col]}}</span></td>
                      </ng-container>

                      <ng-container *ngSwitchCase="'status'">
                        <th mat-header-cell *matHeaderCellDef>Status</th>
                        <td mat-cell *matCellDef="let row">
                          <mat-icon [ngClass]="row[col]?.class" [matTooltip]="row[col]?.description">
                            {{ row[col]?.icon }}
                          </mat-icon>
                        </td>
                      </ng-container>

                      <ng-container *ngSwitchDefault>
                        <th mat-header-cell *matHeaderCellDef>{{colDictionary[col]}}</th>
                        <td mat-cell class="text-color" *matCellDef="let row">{{row[col]}}</td>
                      </ng-container>

                    </ng-container>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="elementRow"></tr>

                </table>
              </ng-container>
            </div>
          </mat-card-content>

          <mat-card-actions>
            <ng-container *ngFor="let but of loadbuttons">
              <button mat-flat-button color="primary" (click)="doget(but.path)">
                <mat-icon [matTooltip]="but.description">check_circle</mat-icon>
                {{but.label}}
              </button>
            </ng-container>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </ng-container>
</div>
