<div class="container">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="form">
        <mat-card>
          <mat-card-content>
            <form [formGroup]="form">
              <ng-container *ngFor="let key of ckeys">
                <mat-card-title>{{key | formTitle: forms}}</mat-card-title>
                <dynamic-form [data]="forms" [controlName]="key" (searchEvent)="searchHandler($event)"></dynamic-form>
              </ng-container>
            </form>
          </mat-card-content>
          <mat-card-actions>
            <ng-container *ngFor="let btn of buttons">
              <button mat-flat-button color="primary" [disabled]="form.invalid"
                (click)="dopost(btn.path)">{{btn.label}}</button>
            </ng-container>
            
            <div *ngIf="message" class="message">
              {{ message }}
            </div>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </div>
  </div>
</div>
