<form class="dynamic" [formGroup]="parentForm">
  <div *ngIf="show; else formhidden">
    <div [formGroupName]="controlName" *ngIf="!!formD">
      <div class="box" *ngFor="let child of keysF; index as i; "
        [ngClass]="{error: subdata.fields[i].error, date: subdata.fields[i].type === 'date'}"
        [ngStyle]="{'width': !isMobile ? (subdata.fields[i].width || '50%'): '100%'}">
        <!-- <pre>subdata.fields[i]: {{ subdata.fields[i] | json}}</pre>-->

        <ng-container [ngSwitch]="types[child]">
          <ng-container *ngSwitchCase="'select'">
            <mat-form-field appearance="fill">
              <mat-label>{{labels[child]}}:</mat-label>
              <mat-select matInput [formControlName]="child" disableInput hideInput [expression]="expression[child]"
                [controlName]="child" [data]="subdata.fields[i]" [rootForm]="parentForm">
                <mat-option [value]="option.id" *ngFor="let option of (options[child])">
                  <span>{{ option.name }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="'region'">
            <mat-form-field appearance="fill">
              <mat-label>{{labels[child]}}:</mat-label>
              <mat-select matInput [formControlName]="child" disableInput hideInput [expression]="expression[child]"
                [controlName]="child" [data]="subdata.fields[i]" [rootForm]="parentForm">
                <mat-option [value]="option" *ngFor="let option of (regiones)">
                  <span>{{ option }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="'comuna'">
            <mat-form-field appearance="fill">
              <mat-label>{{labels[child]}}:</mat-label>
              <mat-select matInput [formControlName]="child" disableInput hideInput [expression]="expression[child]"
                [controlName]="child" [data]="subdata.fields[i]" [rootForm]="parentForm">
                <mat-option [value]="option" *ngFor="let option of (comunas)">
                  <span>{{ option }}</span>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </ng-container>
          <ng-container *ngSwitchCase="'file'">
            <div class="col-12 px-0">
              <button #file mat-flat-button color="primary">{{labels[child]}}
                <input [formControlName]="child" disableInput hideInput [formControlName]="child"
                  [width]="widths[child]" disableInput hideInput [expression]="expression[child]" [controlName]="child"
                  [data]="subdata.fields[i]" [rootForm]="parentForm" (change)="onFileChange($event)"
                  style="opacity: 0; position:absolute; left:0px; top:0px; width:100%; height:100%;" type="file" />
              </button>
            </div>
            <div class="col-12 px-0">
              <ng-container *ngIf="parentForm.get(controlName).get(child).value">
                <span>{{parentForm.get(controlName).get(child).value}}</span>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'notInput'">
          </ng-container>

          <ng-container *ngSwitchCase="'password'">
            <mat-form-field appearance="fill">
              <mat-label>{{labels[child]}}:</mat-label>
              <input matInput type="password" [placeholder]="labels[child]" [formControlName]="child"
                [width]="widths[child]" disableInput hideInput [expression]="expression[child]" [controlName]="child"
                [data]="subdata.fields[i]" [rootForm]="parentForm">
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="'searchStockData'">
            <div class="col-8 px-0">
              <mat-form-field appearance="fill">
                <mat-label>{{labels[child]}}:</mat-label>
                <input matInput type="text" [placeholder]="labels[child]" [formControlName]="child"
                  [width]="widths[child]" disableInput hideInput [expression]="expression[child]" [controlName]="child"
                  [data]="subdata.fields[i]" [rootForm]="parentForm">
              </mat-form-field>
            </div>
            <div class="col-4 px-0">
              <button mat-flat-button color="primary" (click)="onSearch(path, parentForm.get(controlName).get(child).value)">Buscar</button>
            </div>
          </ng-container>

          <ng-container *ngSwitchDefault>
            <mat-form-field appearance="fill">
              <mat-label>{{labels[child]}}:</mat-label>
              <input matInput type="text" [placeholder]="labels[child]" [formControlName]="child"
                [width]="widths[child]" disableInput hideInput [expression]="expression[child]" [controlName]="child"
                [data]="subdata.fields[i]" [rootForm]="parentForm">
            </mat-form-field>
          </ng-container>

        </ng-container>
      </div>
    </div>
  </div>
</form>

<ng-template #formhidden>
  <h4>Completa los datos solicitados para continuar</h4>
</ng-template>
