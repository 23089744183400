import { Injectable } from '@angular/core';
import { Observable, Subject, of } from 'rxjs';
import { delay } from "rxjs/operators";
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { LIST_URL, APP } from 'src/environments/environment';

interface IDataset {
  data: number[];
  label: string;
  backgroundColor: string;
  borderColor: string;
}

interface IIndicator {
  chartTime: string;
  data: number
}

export interface IChartData {
  labels: string[];
  datasets: IDataset[];
}

export interface IEntries {
  _id: string;
  code: string;
  descriptionShort: string;
  type: string;
  status: boolean;
  selected?: boolean;
  [key:string]: any;
};

export interface IFilters {
  key: string;
  placeholder: string;
}

export interface IColumns {
  key: string;
  title: string;
}

export interface IActions {
  code: string;
  description: string;
  icon: string;
}

export interface IDetail {
  comments?: [{ [key: string]: string }],
  form?: [{ [key: string]: string }],
  payments?: [{ [key: string]: string }]
  products?: [{ [key: string]: string }]
}

export interface IPagination {
  count?: number;
  page?: number;
  pages?: number;
  perPage?: number;
}

export interface IResponse {
  body?: {
    entries?: IEntries[];
    hasNext: boolean;
    render?: {
      columns: IColumns[];
      filters?: IFilters[];
      _actions?: IActions[];
      rolesTranslate?: any;
      header?: any;
      _detail?: IDetail;
      pagination?: IPagination,
      title?: string
    }
  },
  header?: {
    render?: {
      forms?: {
        actions: any[];
        fields: any[];
        key: string;
        title: string;
      }[]
    }
  }
}

export interface IQuery {
  key: string;
  value: string;
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  indicatorSubject = new Subject<IIndicator>();

  constructor(
    private http: HttpClient,
    private util: UtilsService
  ) { }

  getChartLineData(idStore?: string): Observable<IChartData> {
    return of({
      labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio'],
      datasets: [
        {
          data: [65, 59, 80, 81, 56, 55, 40],
          label: 'Series A',
          backgroundColor: this.util.hexToRgba('#a16eff', 0.3),
          borderColor: '#a16eff',
        }, {
          data: [28, 48, 40, 19, 86, 27, 90],
          label: 'Series B',
          backgroundColor: this.util.hexToRgba('#ff3d71', 0.3),
          borderColor: '#ff3d71',
        }, {
          data: [18, 48, 77, 9, 100, 27, 40],
          label: 'Series C',
          backgroundColor: this.util.hexToRgba('#0095ff', 0.3),
          borderColor: '#0095ff',
        },
      ]
    } as unknown as IChartData).pipe(delay(2000));
  }

  getChartAdvancedPieData(idStore?: string): Observable<IChartData> {
    return of({
      labels: ['Chile', 'Francia', 'Alemania'],
      datasets: [
        {
          data: [65, 59, 80],
          label: 'Paises',
          backgroundColor: [
            this.util.hexToRgba('#a16eff', 0.3),
            this.util.hexToRgba('#ff3d71', 0.3),
            this.util.hexToRgba('#0095ff', 0.3),
          ],
          borderColor: [
            '#a16eff',
            '#ff3d71',
            '#0095ff'
          ],
        }
      ]
    } as unknown as IChartData).pipe(delay(2000));
  }

  getList(key?: string, pagination?: IPagination, query?: IQuery[]): Observable<IResponse> {
    let url = LIST_URL.slice();
    url = url.replace(':key', key);
    pagination = (!!pagination?.page) ? pagination : { page: 1, perPage: 40 };
    let params = new HttpParams()
      .set('page', String(pagination.page));
    // .append('perPage', String(pagination.perPage)) // esta fijo en 40
    if (query?.length > 0) {
      query.forEach(({ key, value }) => {
        params = params.append(key, value);
      })
    }
    const options = { params: params };

    return this.http.get<IResponse>(url, options);
  }

  load(url, data?): Observable<any> {
    const host = APP.slice();
    return this.http.post(`${host}${url}`, data || {});
  }

  saveUser(user: any, url: string) {
    const host = APP.slice();
    return this.http.post(`${host}${url}`, { ...user });
  }

  saveCoupon(coupon: any) {
    console.log(coupon);
    throw new Error("Method not implemented.");
  }

  saveComment(comment: any, url) {
    const host = APP.slice();
    return this.http.post(`${host}${url}`, { ...comment });
  }

  triggerLoad(url: any): Observable<any> {
    const host = APP.slice();
    return this.http.post(`${host}${url}`, {});
  }

  get(url, queryParams?): Observable<any> {
    const host = url.indexOf('https://') === 0 ? '' : APP.slice();
    let query = undefined;
    if (queryParams) {
      query = {
        params: {
        }
      };
      Object.entries(queryParams)
        .forEach(([key, value]) => {
          query.params[key] = value;
        })
    }
    return this.http.get(`${host}${url}`, query);
  }

  post(url, body = {}): Observable<any> {
    const host = url.indexOf('https://') === 0 ? '' : APP.slice();
    return this.http.post(`${host}${url}`, body);
  }

  delete(url): Observable<any> {
    const host = APP.slice();
    return this.http.delete(`${host}${url}`);
  }
}
