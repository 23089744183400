<div class="container">
  <div class="row">
    <div class="col-12">
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="form">
        <mat-card>
          <mat-card-content>
            <form [formGroup]="form">
              <ng-container *ngFor="let key of ckeys">
                <mat-card-title>{{key | formTitle: forms}}</mat-card-title>
                <mat-card-subtitle>{{message}}</mat-card-subtitle>
                <dynamic-form [data]="forms" [controlName]="key"></dynamic-form>
              </ng-container>
            </form>
          </mat-card-content>
          <mat-card-actions>
            <ng-container *ngFor="let but of buttons">
              <ng-container *ngIf="but.action === 'uploadFile' else getaction">
                <button mat-flat-button color="primary" [disabled]="form.invalid">{{but.label}}</button>
              </ng-container>
              <ng-template #getaction>
                <button mat-flat-button color="primary" [disabled]="form.invalid"
                  (click)="dopost(but.path)">{{but.label}}</button>
              </ng-template>
            </ng-container>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </div>
  </div>
</div>
