import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'html-creator',
  templateUrl: './html-creator.component.html',
  styleUrls: ['./html-creator.component.scss']
})
export class HtmlCreatorComponent implements OnInit {

  columns = [];
  columnRegister = [];

  constructor() { }

  ngOnInit(): void {
  }

  addCol() {
    this.columns.push([]);
    this.columnRegister.push({
      title: '',
      description: ''
    });
  }

  removeCol() {
    this.columns.splice(-1, 1);
    this.columnRegister.splice(-1, 1);
  }

  agregarDetail(title, description, index) {

    if (title){
      let obj = {
        title,
        description
      };
      this.columns[index].push(obj);
      this.columnRegister[index].title = '';
      this.columnRegister[index].description = '';
    }
  }


}
