import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartType } from 'chart.js';
import { ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.scss']
})
export class BarComponent implements OnInit {
  @Input() barChartLabels: Label[] = [];
  @Input() barChartData: ChartDataSets[] = [];
  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartType: ChartType = 'bar';

  constructor() { }

  ngOnInit(): void {
    
  }

}
