<div class="container">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="form">
        <mat-card>
          <form [formGroup]="form">
            <mat-card-content>
              <ng-container *ngFor="let key of ckeys">
                <mat-card-title>{{key | formTitle: forms}}</mat-card-title>
                <dynamic-form [data]="forms" [controlName]="key" (uploadEvent)="fileHandler($event)"></dynamic-form>
              </ng-container>
            </mat-card-content>
          </form>
          <mat-card-actions>
            <mat-spinner *ngIf="loading else showbuttons"></mat-spinner>
            <ng-template #showbuttons>
              <ng-container *ngFor="let but of buttons">
                <ng-container *ngIf="but.action === 'uploadFile' else getaction">
                  <button mat-flat-button color="primary" [disabled]="form.invalid"
                    (click)="upload(but.path)">{{but.label}}</button>
                </ng-container>
                <ng-template #getaction>
                  <button mat-flat-button color="primary" [disabled]="form.invalid"
                    (click)="dopost(but.path)">{{but.label}}</button>
                </ng-template>
              </ng-container>
            </ng-template>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </div>
  </div>