import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'i-forgot-password',
  templateUrl: './i-forgot-password.component.html',
  styleUrls: ['./i-forgot-password.component.scss']
})
export class IForgotPasswordComponent implements OnInit {
  showForm: boolean;
  iforgotForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.showForm = true;
    this.setForm();
  }

  setForm(): void {
    this.iforgotForm = this.fb.group({
      mail: ['', Validators.compose([
        Validators.required,
        Validators.email
      ])],
    });
  }

  send(): void {
    this.showForm = false;
    this.authService
      .iForgotPassword(this.iforgotForm.value)
      .subscribe(
        res => {
          console.log(res);
          this.iforgotForm.reset();
          const ok = this.snackBar.open('Solicitud enviada, revisa tu correo para continuar el proceso, no olvides checkear la carpeta spam', 'OK', {
            duration: 1000 * 60 * 30
          });
          ok.afterDismissed().subscribe(() => this.router.navigate(['login']));
        },
        err => {
          console.error(err);
          this.showForm = true;
          this.snackBar.open('Hubo un problema intenta más tarde', 'OK', {
            duration: 1000 * 60 * 30
          });
        }
      );
  }

  get show(): boolean {
    return this.showForm;
  }
}
