import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { IDetail } from 'src/app/services/data.service';

@Component({
  selector: 'detail-orders',
  templateUrl: './detail-orders.component.html',
  styleUrls: ['./detail-orders.component.scss']
})
export class DetailOrdersComponent implements OnInit {
  @Input() element: any;
  detail: IDetail;

  constructor(
  ) { }

  ngOnInit(): void {
    this.detail = this.element._detail; 
  }

}
