import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { FormBaseComponent } from '../form-base/form-base.component';

@Component({
  selector: 'label-stock',
  templateUrl: './label-stock.component.html',
  styleUrls: ['./label-stock.component.scss']
})
export class LabelStockComponent extends FormBaseComponent implements OnInit {
  private _loading: boolean;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public dataService: DataService,
    private snackbar: MatSnackBar
  ) {
    super(fb, dialog);
  }

  ngOnInit(): void {
    this._loading = false;
    this.initStock()
  }

  initStock() {
    this.dataService.get(this.router.url)
      .subscribe(data => {
        this.config = data;
        this.getForm();
      });
  }

  get loading(): boolean {
    return this._loading;
  }

  get buttons(): any[] {
    if (!this.config?.body?.render?.forms?.some(f => !!f.actions[0].path)) {
      return [];
    }
    return this.config?.body?.render?.forms.map(f => f.actions).flat();
  }

  get message(): string {
    return this.config?.header?.render?.message?.label || '';
  }

  dopost(path) {
    this.dataService.post(path, this.getFlatValue())
      .subscribe(
        () => {
          this.snackbar.open('Carga Realizada');
          this.fullreset();
        });
  }

  fullreset() {
    this.form.reset();
    this.form = undefined;
    this.initStock();
  }

  private getForm() {
    const form = this.config?.body?.render?.forms;
    if (form) {
      this.forms = form;
    }
    this.values = {};
    this.setForm();
  }

  private getFlatValue() {
    const flat: { [key: string]: string } = {};
    this.ckeys.forEach(group => {
      Object.entries(this.form.get(group).value)
        .forEach(([key, val]) => {
          flat[key] = val as string;
        });
    });
    return flat;
  }
}