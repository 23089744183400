<div class="container">
  <div class="row">
    <div class="col-12 px-0">
      <mat-card class="line-chart-card">
        <mat-card-header>
          <h2>Nuevo Cupon</h2>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="couponForm">
            <div class="row">
              <div class="col-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Mail</mat-label>
                  <input matInput placeholder="daryl@dixon.com" formControlName="mail" autocomplete="off" />
                  <mat-error *ngIf="couponForm.get('mail').hasError('email') && !couponForm.get('mail').hasError('required')">
                    Ingrese un email valido
                  </mat-error>
                  <mat-error *ngIf="couponForm.get('mail').hasError('required')">
                    Email es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Código de cupón</mat-label>
                  <input matInput placeholder="" formControlName="couponCode" autocomplete="off" />
                  <mat-error *ngIf="couponForm.get('couponCode').hasError('minlength')">
                    Código de cupón debe ser de largo <strong>10</strong> 
                  </mat-error>
                  <mat-error *ngIf="couponForm.get('couponCode').hasError('required')">
                    Código de cupón es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Monto Mínimo</mat-label>
                  <input matInput formControlName="minAmount" autocomplete="off" />
                  <mat-error *ngIf="couponForm.get('minAmount').hasError('min')">
                    Monto Mínimo debe ser <strong>mayor</strong> a 0
                  </mat-error>
                  <mat-error *ngIf="couponForm.get('minAmount').hasError('required')">
                    Monto Mínimo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Descuento</mat-label>
                  <input matInput placeholder="entre 0 y 100" formControlName="discount" autocomplete="off" />
                  <mat-error *ngIf="couponForm.get('discount').hasError('max') || couponForm.get('discount').hasError('min')">
                    Descuento debe ser entre <strong>0 a 100</strong>
                  </mat-error>
                  <mat-error *ngIf="couponForm.get('discount').hasError('required')">
                    Descuento es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Monto Maximo</mat-label>
                  <input matInput formControlName="maxAmount" autocomplete="off" />
                  <mat-error *ngIf="couponForm.get('maxAmount').hasError('min')">
                    Monto Maximo debe ser <strong>mayor</strong> a 0
                  </mat-error>
                  <mat-error *ngIf="couponForm.get('maxAmount').hasError('required')">
                    Monto Maximo es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
              <div class="col-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Monto</mat-label>
                  <input matInput formControlName="amount" autocomplete="off" />
                  <mat-error *ngIf="couponForm.get('amount').hasError('min')">
                    Monto debe ser <strong>mayor</strong> a 0
                  </mat-error>
                  <mat-error *ngIf="couponForm.get('amount').hasError('required')">
                    Monto es <strong>requerido</strong>
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button [disabled]="couponForm.invalid" (click)="save()" mat-raised-button>Guardar</button>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
