<div class="container">
  <div class="row">
    <div class="col-12 px-0">
      <mat-card class="line-chart-card">
        <mat-card-header>
          <h2>Nuevo usuario</h2>
        </mat-card-header>
        <mat-card-content>
          <form class="user" [formGroup]="userForm">
            <div class="row">
              <div class="col-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Nombre</mat-label>
                  <input matInput placeholder="" formControlName="name" autocomplete="off" />
                </mat-form-field>
              </div>
              <div class="col-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Mail</mat-label>
                  <input matInput placeholder="Ingresa mail" formControlName="email" autocomplete="off" />
                </mat-form-field>
              </div>

              <div class="col-12 col-md-4">
                <mat-form-field appearance="fill">
                  <mat-label>Roles</mat-label>
                  <mat-select formControlName="roles" multiple>
                      <ng-container *ngIf="parsedRoles">
                      <mat-option *ngFor="let item of parsedRoles" [value]="item.value">{{item.label}}</mat-option>
                    </ng-container>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button [disabled]="userForm.invalid" (click)="save()" mat-raised-button>{{btnLabel}}</button>
              </div>
            </div>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
