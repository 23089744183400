import { EventEmitter, Output } from '@angular/core';
import { Component, OnInit, Input } from '@angular/core';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'dynamic-form',
  templateUrl: 'dynamic-form.component.html',
  styleUrls: ['dynamic-form.component.scss']
})
export class DynamicFormComponent implements OnInit {
  @Input() title!: string;
  @Input() controlName!: string;
  @Input() data!: { [key: string]: any };
  @Output() uploadEvent = new EventEmitter<any>();
  @Output() searchEvent = new EventEmitter<any>();
  parentForm: any;
  subdata: any;
  isMobile: any;
  oldRegion: string;
  currentFile: File;
  fileName: string;
  progress: number;
  message: string;
  pathFile: string;

  constructor(
    private parentControl: ControlContainer
  ) {
    this.oldRegion = '--';
  }

  ngOnInit(): void {
    this.parentForm = this.parentControl.control;
    this.subdata = this.data.find((_subdata: any) => _subdata.key === this.controlName);
  }

  get formD() {
    return this.parentForm?.get(this.controlName);
  }

  get show(): boolean {
    const controlObj = this.data.find((form: any) => form.key === this.controlName);
    if (!controlObj?.expression?.hide) {
      return true;
    }
    const results = Object.entries(controlObj.expression?.hide)
      .map(([key, options]) => {
        const control = this.parentForm.get(key);
        return control[(options as any).type] !== (options as any).value;
      });
    const hide = results.some(res => res === true);
    return !hide;
  }

  get keysF(): string[] {
    return Object.keys(this.formD.controls);
  }

  get labels(): { [key: string]: string } {
    return this.details('label') as any;
  }

  get types(): { [key: string]: string } {
    return this.details('type') as any;
  }

  get widths(): { [key: string]: string } {
    return this.details('width') as any;
  }

  get options(): { [key: string]: { id: string; name: string }[] } {
    return this.details('options') as any;
  }

  get expression(): { [key: string]: any } {
    return this.details('expression') as any;
  }

  get regiones(): string[] {
    return this.details('regiones').region as any;
  }
  
  get comunas(): string[] {
    const all: Array<{ region: string; name: string }> = this.details('comunas').comuna as any;
    const selected = this.formD.get('region').value;
    if (selected === '') {
      return [];
    }
    if (this.oldRegion !== selected) {
      this.oldRegion = selected;
      this.formD.get('comuna').patchValue('');
    }
    return all.filter(com => com.region === selected).map(com => com.name);
  }

  get path(): string {
    return (this.details('option').stockCode as any).path as any;
  }

  get selectedfile(): string {
    return this.pathFile
  }

  onFileChange(event) {
    this.uploadEvent.emit(event);
    this.pathFile = event?.target?.files[0]?.name;
  }

  onSearch(path, stockCode) {
    this.searchEvent.emit({path, stockCode});
  }

  private details(key: string): { [key: string]: string | string[] } {
    const details: any = {};
    if (this.data) {
      this.data
        .find((d: any) => d.key === this.controlName)
        .fields.map((f: any) => details[f.key] = f[key]);
    }
    return details;
  }
}
