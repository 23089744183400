import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import localeEsCl from '@angular/common/locales/es-CL';
import localeEsClExtra from '@angular/common/locales/extra/es-CL';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './components/login/login.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SocketIoModule } from 'ngx-socket-io';

// MATERIAL IMPORTS
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSnackBarModule, MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { ErrorStateMatcher, MatNativeDateModule, MAT_DATE_LOCALE, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ChartsModule } from 'ng2-charts';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LineComponent } from './components/charts/line/line.component';
import { AdvancedPieComponent } from './components/charts/advanced-pie/advanced-pie.component';
import { TodayProfitComponent } from './components/charts/today-profit/today-profit.component';
import { ProfitComponent } from './components/ecomerce/profit/profit.component';
import { ListComponent } from './components/list/list.component';
import { AutorizationInterceptor } from './core/interceptors/autorization.interceptor';
import { ResAuthInterceptor } from './core/interceptors/res-auth.interceptor';
import { RouterModule } from '@angular/router';
import { UserComponent } from './components/user/user.component';
import { DetailOrdersComponent } from './components/detail-orders/detail-orders.component';
import { DetailCommentsComponent } from './components/detail-comments/detail-comments.component';
import { CouponComponent } from './components/coupon/coupon.component';
import { LoadButtonsComponent } from './components/load-buttons/load-buttons.component';
import { HtmlCreatorComponent } from './components/html-creator/html-creator.component';
import { MimicBackendInterceptor } from './core/interceptors/mimic-backend.interceptor';
import { DynamicFormComponent } from './components/dynamic-form/dynamic-form.component';
import { HideInputDirective } from './directives/hide-input.directive';
import { FormMasterComponent } from './components/form-master/form-master.component';
import { FormBaseComponent } from './components/form-base/form-base.component';
import { FormTitlePipe } from './pipes/form-title.pipe';
import { ComparedPipe } from './pipes/compared.pipe';
import { FormManifestComponent } from './components/form-manifest/form-manifest.component';
import { PrintDialogComponent } from './components/print-dialog/print-dialog.component';
import { ConfimDialogComponent } from './components/confim-dialog/confim-dialog.component';
import { ImageDialogComponent } from './components/image-dialog/image-dialog.component';
import { LabelStockComponent } from './components/label-stock/label-stock.component';
import { BarComponent } from './components/charts/bar/bar.component';
import { registerLocaleData } from '@angular/common';
import { FormUserComponent } from './components/form-user/form-user.component';
import { FormManualManifestComponent } from './components/form-manual-manifest/form-manual-manifest.component';
import { IForgotPasswordComponent } from './components/i-forgot-password/i-forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { FormStorageBinComponent } from './components/form-storage-bin/form-storage-bin.component';
import { FormWarehouseComponent } from './components/form-warehouse/form-warehouse.component';
import { FormGrReceptionComponent } from './components/form-gr-reception/form-gr-reception.component';
import { SOCKET_CONFIG } from 'src/environments/environment';
import { ActiveSidebarOptDirective } from './directives/active-sidebar-opt.directive';
import { LoggerInterceptor } from './core/interceptors/logger.interceptor';

registerLocaleData(localeEsCl, 'es-CL', localeEsClExtra);
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SidebarComponent,
    HeaderComponent,
    DashboardComponent,
    LineComponent,
    AdvancedPieComponent,
    TodayProfitComponent,
    ProfitComponent,
    FormTitlePipe,
    ComparedPipe,
    ListComponent,
    UserComponent,
    DetailOrdersComponent,
    DetailCommentsComponent,
    CouponComponent,
    LoadButtonsComponent,
    HtmlCreatorComponent,
    HideInputDirective,
    DynamicFormComponent,
    FormMasterComponent,
    FormBaseComponent,
    FormManifestComponent,
    PrintDialogComponent,
    ConfimDialogComponent,
    ImageDialogComponent,
    LabelStockComponent,
    BarComponent,
    FormUserComponent,
    FormManualManifestComponent,
    IForgotPasswordComponent,
    ResetPasswordComponent,
    FormStorageBinComponent,
    FormWarehouseComponent,
    FormGrReceptionComponent,
    ActiveSidebarOptDirective,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatGridListModule,
    MatSelectModule,
    MatMenuModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatSortModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    LayoutModule,
    MatSnackBarModule,
    MatNativeDateModule,
    MatDatepickerModule,
    ChartsModule,
    MatCheckboxModule,
    MatProgressBarModule,
    SocketIoModule.forRoot(SOCKET_CONFIG)
  ],
  providers: [
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher
    },
    { provide: LOCALE_ID, useValue: 'es-CL' },
    { provide: MAT_DATE_LOCALE, useValue: 'es-CL' },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: { duration: 1000 * 10}
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MimicBackendInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AutorizationInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResAuthInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
