<div class="container">
  <div class="row">
    <div class="col-12 px-0">
      <mat-card class="line-chart-card">
        <mat-card-content>
          <div class="button-content row justify-content-between">
            <h2>Cargador de productos</h2>
            <div class="buttons">
              <button (click)="sync()" *ngIf="buttons?.imagesSync" mat-raised-button>
                <mat-spinner *ngIf="syncing === true" class="d-inline-flex" diameter="20"></mat-spinner>
                Sincronizar imágenes</button>
              <button (click)="go()" mat-raised-button>Ir al excel de carga</button>
              <button (click)="trigger()" mat-raised-button>
                <mat-spinner *ngIf="loading === true" class="d-inline-flex" diameter="20"></mat-spinner>
                Gatillar una subida a QA
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
