import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LABEL_TOKEN, LABEL_USER } from '../config/config.constans';
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FORGOT_URL, LOGIN_URL, RESET_URL } from '../../environments/environment';
import { User, IRespUser } from "../classes/user";
import { delay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public user: User;
  public userSubject = new Subject<User | undefined>();
  private token: string;

  constructor(
    private http: HttpClient,
    private router: Router
  ) {}

  login(loginData): Observable<any> {
    return new Observable(obs => {
      try {
        this.http
          .post<IRespUser>(LOGIN_URL, loginData)
          .subscribe(
            response => {
              this.user = new User(response);
              this.token = response.token;

              this.userSubject.next(this.user);
              obs.next(true);
              obs.complete();
            },
            err => {
              this.goOut(err);
              obs.error(false);
              obs.complete()
            });
      } catch (error) {
        console.log(error);
        obs.error(false);
        obs.complete()
      }
    })
  }

  goOut(err?: any) {
    if (err) console.error(err);
    this.user = undefined;
    this.token = undefined;
    this.userSubject.next(null);
    this.router.navigate(['']);
  }

  get isLoggedIn(): boolean {
    return !!this.user && !!this.token;
  }

  getToken(): string {
    return this.token;
  }

  getRoute() {
    const [first] = this.user.menu;
    return first.route;
  }

  iForgotPassword({ mail: email }) {
    return this.http.post(FORGOT_URL, { email });
  }

  reset({ token, mail: email, newPassword }) {
    return this.http.post(RESET_URL, { token, email, newPassword });
  }
}
