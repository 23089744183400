<div class="container">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="form">
        <mat-card>
          <mat-card-content>
            <form [formGroup]="form">
              <ng-container *ngFor="let key of ckeys">
                <mat-card-title>{{key | formTitle: forms}}</mat-card-title>
                <dynamic-form [data]="forms" [controlName]="key"></dynamic-form>
              </ng-container>
            </form>
          </mat-card-content>
          <mat-card-actions>

            <button mat-flat-button color="primary" [disabled]="form.invalid"
              (click)="dopost(router.url)">{{labelbtn}}</button>
            <button class="ml-10" mat-flat-button color="primary"
              [routerLink]="'/list/user'">Volver</button>
            <div *ngIf="message" class="message">
              {{ message }}
            </div>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </div>
  </div>
</div>
