<div class="container">
  <div class="row">
    <!-- <line class="col-lg-6 col-md-12 charts" *ngIf="lineChartData" [chartData]="lineChartData"></line>
    <advanced-pie class="col-lg-6 col-md-12 charts" *ngIf="advancedPieData" [chartData]="advancedPieData"></advanced-pie> -->
    <!-- <profit class="col-lg-6 col-md-12 charts" *ngIf="profitData" [profits]="profitData"></profit> -->

    <mat-card class="col-12" *ngIf="general?.stockData">
      <mat-card-header>
        <mat-card-title>Detalle de Stock</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <div class="row">
          <div class="col-6">
            <span>
              Etiquetas Disponibles: <b [ngClass]="{'red': general?.stockData?.amount <= general?.stockData?.minLimitAmount}">{{general?.stockData?.amount || ''}}</b>
            </span>
          </div>
          <div class="col-6">
            <span>
              Fecha última compra:
              {{(general?.stockData?.lastSettedAmountDate | date: 'short': 'America/Santiago' ) || ''}}
            </span>
          </div>
          <div class="col-6">
            <span>
              Cantidad última compra: {{general?.stockData?.lastSettedAmount || ''}}
            </span>
          </div>
          <div class="col-6">
            <span>
              Stock de control: {{general?.stockData?.minLimitAmount || ''}}
            </span>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <mat-card class="col-12">
      <mat-card-header>
        <mat-card-title>Detalle por usuario</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <form [formGroup]="dateForm">
          <div class="row">
            <div class="col-lg-3 col-md-12">
              <mat-form-field appearance="fill">
                <mat-label>Fecha inicio:</mat-label>
                <input class="w-100" matInput [matDatepicker]="picker" formControlName="startDate" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-lg-3 col-md-12">
              <mat-form-field appearance="fill">
                <mat-label>Fecha Fin:</mat-label>
                <input class="w-100" matInput [matDatepicker]="pickerend" formControlName="endDate" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="pickerend"></mat-datepicker-toggle>
                <mat-datepicker #pickerend></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
        </form>
        <div class="row">
          <div class="col-10 offset-md-1">
            <bar [barChartLabels]="barLabels" [barChartData]="barData"></bar>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

  </div>
</div>
