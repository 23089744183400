import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Params } from '@angular/router';
import { IField } from 'src/app/classes/expression';

@Component({
  template: `
    <p>
      form-base works!
    </p>
  `,
  styles: [
  ]
})
export class FormBaseComponent {
  form: FormGroup;
  values: Params;
  forms!: { key: string; title: string; fields: IField[] }[];
  config: any;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog
  ) {
  }

  newInput(field: IField): FormControl {
    const validArr = [];
    const { required, validators, key, option, value } = field;
    if (required) {
      validArr.push(Validators.required);
    }
    const control = new FormControl(this.recover(key, value, option));
    if (validators) {
      validators.forEach(validator => {
        switch (validator.type) {
          case 'email':
            validArr.push(Validators.email);
            break;
          case 'requiredTrue':
            validArr.push(Validators.requiredTrue);
            break;
          case 'min':
            if (validator.restriction) {
              validArr.push(Validators.min(validator.restriction));
            }
            break;
          case 'max':
            if (validator.restriction) {
              validArr.push(Validators.max(validator.restriction));
            }
            break;
          case 'minLength':
            if (validator.restriction) {
              validArr.push(Validators.minLength(validator.restriction));
            }
            break;
          case 'maxLength':
            if (validator.restriction) {
              validArr.push(Validators.maxLength(validator.restriction));
            }
            break;
        }
      });
    }
    control.setValidators(validArr);
    return control;
  }

  private recover(key: string, byDefault: string = '', params?): any {
      if(params?.readonly) {
      return {value: this.values[key] || byDefault, disabled: true}
    }
    return this.values[key] || byDefault;
  }


  setForm() {
    this.form = this.fb.group({});
    if (this.forms) {
      this.forms.forEach(form => {
        const fields = this.fb.group({});
        form.fields.forEach(field => {
          fields.addControl(field.key, this.newInput(field));
        });
        this.form.addControl(form.key, fields);
      });
    }
  }

  get ckeys(): string[] {
    return Object.keys(this.form.controls);
  }

  get cforms(): FormArray {
    return this.form.get('forms') as FormArray;
  }
}
