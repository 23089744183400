import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { AuthService } from './auth.service';
import { Storage, Menu } from '../classes/store';
import { Router } from '@angular/router';
import { User } from '../classes/user';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  selectedStorage: Storage;
  selectedStoreSubject: BehaviorSubject<Storage>;
  storages: Storage[];
  storesSubject: BehaviorSubject<Storage[]>;
  sidebarOpts: Menu[];
  sidebarOptsSubject: BehaviorSubject<Menu[]>;
  user: User;

  constructor(
    private auths: AuthService,
    private router: Router
  ) {
    this.selectedStoreSubject = new BehaviorSubject(undefined);
    this.storesSubject = new BehaviorSubject(undefined);
    this.sidebarOptsSubject = new BehaviorSubject(undefined);
    this.auths.userSubject
      .subscribe(user => {
        if (!user) {
          this.storages = null;
          this.storesSubject.next(null);
          return;
        }
        this.user = user;
        this.storages = user.storages;
        const [first] = user.storages;
        this.selectStorage(first.id);
        this.storesSubject.next(this.storages);
      })
  }

  getStorages(): Observable<Storage[]> {
    return of(this.storages);
  }

  getStorage(id: string): Observable<Storage> {
    return new Observable(obs => {
      const storage = this.storages.find(sh => sh.id === id);
      obs.next(storage);
    })
  }

  selectStorage(id: string) {
    const store = this.storages.find(store => store.id === id);
    if (store) {
      this.selectedStorage = store;
      this.selectedStoreSubject.next(store);
      this.sidebarOpts = this.user.menu;
      this.sidebarOptsSubject.next(this.user.menu);
      this.resolveRoute();
    } else {
      this.selectedStorage = undefined;
      this.sidebarOpts = undefined;
      this.selectedStoreSubject.next(undefined);
      this.sidebarOptsSubject.next(undefined);
    }
  }

  resolveRoute() {
    const flatRoutes = this.sidebarOpts.map(menu => menu.route.join('/'));
    if (flatRoutes.indexOf(this.router.url) < 0) {
      const [first] = this.sidebarOpts;
      this.router.navigate(first.route);
    }
  }

}
