import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ConfirmPasswordValidator } from 'src/app/validators/passwordConfirm.validator';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  showForm: boolean;
  token: string;
  mail: string;
  resetForm: FormGroup;
  answer: string;

  constructor(
    public fb: FormBuilder,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.showForm = true;
    this.token = this.route.snapshot.paramMap.get('token');
    this.mail = this.route.snapshot.paramMap.get('mail');

    this.resetForm = this.fb.group({
      newPassword: ['', Validators.compose(
        [
          Validators.required,
          Validators.minLength(8)
        ]
      )],
      confirm: ['', Validators.required]
    },
      {
        validator: ConfirmPasswordValidator("newPassword", "confirm")
      }
    );
  }

  send(): void {
    const { mail, token } = this;
    const resetBody = { ...this.resetForm.value, mail, token };

    this.showForm = false;
    this.authService
      .reset(resetBody)
      .subscribe(
        res => {
          this.resetForm.reset();
          const ok = this.snackBar.open('Tu contraseña a cambiado correctamente', 'OK', {
            duration: 1000 * 60 * 30
          });
          ok.afterDismissed().subscribe(() => this.router.navigate(['login']));
        },
        err => {
          this.showForm = true;
          const error = this.snackBar.open('Hubo un problema intenta más tarde', 'Cerrar', {
            duration: 1000 * 60 * 30
          });
        }
      );
  }

  get show(): boolean {
    return this.showForm;
  }
}
