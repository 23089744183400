<div class="container-fluid">
  <div class="row">
    <h2>Commentarios</h2>
  </div>
  <div class="row oldComments" *ngIf="comments.length > 0">
    <div class="col-12" *ngFor="let item of comments">
      <div class="row">
        <div class="col">
          {{item.stamp | date: 'short'}}
        </div>
        <div class="col">
          <strong>{{item.user}} :</strong>
        </div>
        <div class="col-6">
          {{item.text}}
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <form [formGroup]="commentForm">
        <mat-form-field>
          <textarea matInput placeholder="Nuevo commentario" formControlName="comment">
          </textarea>
          <mat-error *ngIf="commentForm.get('comment').hasError('required')">
            Commentario es requerido.
          </mat-error>
        </mat-form-field>
      </form>
    </div>
  </div>
  <div class="row" *ngIf="!!comentCart.url">
    <div class="col">
      <button [disabled]="commentForm.invalid" (click)="save()" mat-raised-button>Guardar</button>
    </div>
  </div>
</div>
