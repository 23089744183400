import { SocketIoConfig } from "ngx-socket-io";

export const TITLE = 'SmartBot'
export const HOST = 'https://api-smartbot.rafcom.cl';
export const APP = `${HOST}/label-printer`;
export const LOGIN_URL = `${APP}/login`;
export const LIST_URL = `${APP}/list/:key`;
export const FORGOT_URL = `${APP}/login/resetPassword`;
export const RESET_URL = `${APP}/login/setNewPassword`;
export const GET_PRINTERS_URL = `${APP}/printers`;
export const LOGIN = {
  email: '',
  password: ''
};
export const environment = {
  production: true
};
export const SOCKET_CONFIG: SocketIoConfig = { url: HOST, options: { reconnection: true, path: "/label-printer/socket.io", query: { type: "front" } } };