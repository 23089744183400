import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { FormBaseComponent } from '../form-base/form-base.component';

@Component({
  selector: 'form-user',
  templateUrl: './form-user.component.html',
  styleUrls: ['./form-user.component.scss']
})
export class FormUserComponent extends FormBaseComponent implements OnInit {
  private _message: string;
  private _loading: boolean;

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public dataService: DataService,
    private snackbar: MatSnackBar
  ) {
    super(fb, dialog);
  }

  ngOnInit(): void {
    this._loading = false;
    this.dataService.get(this.router.url)
      .subscribe(data => {
        this.config = data;
        this.getForm();
      });
  }

  doget(path) {
    this.dataService.get(path)
      .subscribe((data) => { console.log('===>🚀 data 🚀', data); });
  }

  get message(): string {
    return this._message;
  }

  get loading(): boolean {
    return this._loading;
  }

  get buttons(): any[] {
    if (!this.config?.body?.render?.forms?.some(f => !!f.actions[0].path)) {
      return [];
    }
    return this.config?.body?.render?.forms.map(f => f.actions).flat();
  }

  get labelbtn(): string {
    return this.router.url.match(/new/) ? 'Nuevo Usuario' : 'Grabar Usuario';
  }

  dopost(path) {
    this.dataService.post(path, this.form.get('userDetail').value)
      .subscribe(
        () => {
          this.snackbar.open('Usuario Creado');
          this.form.reset();
          this.router.navigate(['list', 'user']);
        },
        err => {
          this.snackbar.open('Error, usuario no creado');
        }
      );
  }



  private getForm() {
    const [form] = this.config?.body?.render?.forms;
    if (form) {
      this.forms = [form];
    }
    this.values = form.values || {};
    this.setForm();
  }

}
