import { AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[activeSidebarOpt]'
})
export class ActiveSidebarOptDirective implements AfterViewInit, OnChanges {
  @Input('route') route!: string;
  @Input('link') link: string[];

  constructor(
    private renderer: Renderer2,
    private hostElement: ElementRef
  ) { }

  ngAfterViewInit() {
    this.prepare();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.prepare()
  }

  prepare() {
    this.renderer.removeClass(this.hostElement.nativeElement, 'active');
    if (this.link.join('/') === this.route){
      this.renderer.addClass(this.hostElement.nativeElement, 'active');
    }
  }
}
