/* eslint-disable @angular-eslint/no-input-rename */
import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { IExpression, IField } from '../classes/expression';

@Directive({
  selector: '[hideInput]'
})
export class HideInputDirective implements AfterViewInit {
  @Input('expression') expression!: IExpression;
  @Input('controlName') controlName?: string;
  @Input('rootForm') rootForm!: any;
  @Input('data') data!: IField;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
  }

  ngAfterViewInit() {
    this.show();
    this.rootForm.valueChanges
      ?.subscribe(() => {
          this.show();
      });
  }

  private show(): void {
    if (!this.data?.expression?.hide) {
      return;
    }
    const results = Object.entries(this.data?.expression?.hide)
      .map(([key, options]) => {
        if (this.rootForm.get(key)) {
          const control = this.rootForm.get(key);
          return control[(options as any).type] !== (options as any).value;
        }
        return false;
      });
    const hide = results.some(res => res === true);
    if (hide) {
      this.renderer.setStyle(this.el.nativeElement, 'display', `none`);
      return;
    }
    this.renderer.setStyle(this.el.nativeElement, 'display', `box`);
  }
}
