import { Component, OnInit } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.scss']
})
export class CouponComponent implements OnInit {
  couponForm: FormGroup;

  constructor(
    public fb: FormBuilder,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.setForm();
  }

  setForm() {
    this.couponForm = this.fb.group({
      mail: ['', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(2)
      ])],
      couponCode: ['', Validators.compose([
        Validators.required,
        Validators.minLength(10)
      ])],
      minAmount: ['', Validators.compose([
        Validators.required,
        Validators.min(0)
      ])],
      discount: ['', Validators.compose([
        Validators.required,
        Validators.max(100),
        Validators.min(0)
      ])],
      maxAmount: ['', Validators.compose([
        Validators.required,
        Validators.min(0)
      ])],
      amount: ['', Validators.compose([
        Validators.required,
        Validators.min(0)
      ])],
      date: ['', Validators.required],
      hour: ['', Validators.required],
      lifetime: ['', Validators.required],
      status: ['', Validators.required],
    });
  }

  save() {
    if (this.couponForm.invalid) {
      return this.couponForm.updateValueAndValidity();
    }
    this.dataService.saveCoupon(this.couponForm.value);
    this.couponForm.reset();
  }
}
