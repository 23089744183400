import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

interface commentCart {
  code: string;
  icon: string;
  description: string;
  url: string;
}
@Component({
  selector: 'detail-comments',
  templateUrl: './detail-comments.component.html',
  styleUrls: ['./detail-comments.component.scss']
})
export class DetailCommentsComponent implements OnInit {
  @Input() element: any;
  @Output() handleRefresh: EventEmitter<any> = new EventEmitter();
  commentForm: FormGroup;
  comments: any;
  comentCart: commentCart;

  constructor(
    public fb: FormBuilder,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.comments = this.element?._detail?.comments || [];
    this.comentCart = this.element?._actions.find(act => act.code === 'comment-cart');

    this.commentForm =  this.fb.group({
      comment: [ '', Validators.compose([
        Validators.required,
        Validators.minLength(2)
      ])]
    });
  }

  save(): void {
    if(this.commentForm.invalid) {
      return this.commentForm.updateValueAndValidity();
    }
    this.dataService.saveComment(this.commentForm.value, this.comentCart.url)
      .subscribe(
        res => {
          this.commentForm.reset();
          this.handleRefresh.emit(res);
        },
        err => console.error(err)
      )
  }
}
