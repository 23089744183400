import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnChanges {
  @Input() user?: any;
  @Input() formData?: { code, url, rolesTranslate: {[key: string]: string} };
  @Output() handleRefresh: EventEmitter<any> = new EventEmitter();
  userForm: FormGroup;
  btnLabel: string;
  parsedRoles: { value: string, label: string }[]

  constructor(
    public fb: FormBuilder,
    private dataService: DataService
  ) {
    this.parsedRoles = [];
  }

  ngOnInit(): void {
    this.btnLabel = (!!this.user) ? 'Editar' : 'Agregar';
    this.setForm();
    this.parseRoles();
  }

  ngOnChanges(): void {
    this.parseRoles();
  }

  parseRoles(): void {
    this.parsedRoles = [];
    if (this.formData.rolesTranslate)
      Object.entries(this.formData.rolesTranslate)
        .forEach(([value, label]) => {
          this.parsedRoles.push({ value, label });
        });
  }

  setForm() {
    this.userForm = this.fb.group({
      name: [this.user?.name || '', Validators.compose([
        Validators.required,
        Validators.pattern(/^[a-zA-ZÀ-ÿ\u00f1\u00d1\s]*$/),
        Validators.minLength(2)
      ])],
      email: [this.user?.email || '', Validators.compose([
        Validators.required,
        Validators.email,
        Validators.minLength(2)
      ])],
      roles: [this.user?.roles || '', Validators.required],
    });
  }

  save() {
    if (this.userForm.invalid) {
      return this.userForm.updateValueAndValidity();
    }
    const { value: user } = this.userForm;
    if (!!this.user) Object.assign(user, { _id: this.user._id });
    this.dataService.saveUser(user, this.formData.url)
      .subscribe(
        res => {
          this.handleRefresh.emit(res)
          this.userForm.reset();
        },
        err => console.error(err)
      )
  }
}
