import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../services/config.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { VALID } from '../../config/config.constans';
import { AuthService } from '../../services/auth.service';
import { Storage, Menu } from 'src/app/classes/store';
import { User } from 'src/app/classes/user';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  activeHeader: boolean;
  storages: Storage[];
  selected: string;
  storageForm: FormGroup;
  user: User;
  links: Menu[];
  logo: string;

  constructor(
    private route: ActivatedRoute,
    private config: ConfigService,
    private auths: AuthService,
    public fb: FormBuilder
  ) {
    this.activeHeader = false;
  }

  ngOnInit(): void {
    this.route.data
      .subscribe(data => {
        const { activeHeader } = data;
        this.activeHeader = activeHeader;
      });
    if (this.config.storages) {
      this.set(this.config.storages);
    }
    this.config.storesSubject
      .subscribe(stores => {
        this.set(stores);
      })
    this.user = this.auths.user;
    this.auths.userSubject
      .subscribe(user => this.user = user);

    this.links = this.config.sidebarOpts;
    this.config.sidebarOptsSubject
      .subscribe(links => this.links = links);

    if (this.config.selectedStorage) {
      this.logo =  '/assets/cross-control-sidebar-gray.png';
    }
  }

  set(storages: Storage[]) {
    this.storages = storages;
    this.selected = this.config.selectedStorage.id;
    this.storageForm = this.fb.group({
      store: [this.selected, [Validators.required]]
    });
    this.storageForm.statusChanges
      .subscribe(status => {
        if (status === VALID) {
          this.config.selectStorage(this.storageForm.get('store').value);
        }
      });
  }

  goLogout(): void {
    this.auths.goOut()
  }

}
