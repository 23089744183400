<div class="container">
    <h1>Herramientas</h1>
    <div class="controls">
        <div class="columnas">
        Columna 
            
            <span class="icon" (click)="removeCol()"><mat-icon>remove</mat-icon></span>
                <div class="qty">{{columns.length}}</div>
            <span class="icon" (click)="addCol()"> <mat-icon>add</mat-icon> </span>
        </div>

        <div class="detail">
            <ng-container *ngFor="let col of columnRegister; let i = index">
                <div class="col">
                    <h3>Columna {{i+1}}</h3>
                    <label for="">Title
                        <input type="text" [(ngModel)]="columnRegister[i].title">
                    </label>
                    <label for="">Contenido
                        <input type="text" [(ngModel)]="columnRegister[i].description">
                    </label>
                    <button mat-button (click)="agregarDetail(columnRegister[i].title, columnRegister[i].description, i)">Agregar</button>
                </div>
            </ng-container>
        </div>
    </div> 


    <code>
        <ng-container *ngFor="let col of columns">
            &lt;ul class="col"&gt;
            <ng-container *ngFor="let det of col">
                &lt;li class="line"&gt;
                    &lt;span class="title"&gt; {{det.title}} &lt;/span&gt;    
                    <ng-container *ngIf="det.description">
                        &lt;span class="description"&gt; {{det.description}} &lt;/span&gt;   
                    </ng-container>    
                &lt;/li&gt;
            </ng-container>
            &lt;/ul&gt;
        </ng-container>
    </code>

    <div class="detail-style">
        <ng-container *ngFor="let col of columns">
            <ul class="col">
            <ng-container *ngFor="let det of col">
                <li class="line">
                    <span class="title" [ngClass]="{'upper': det.description}"> {{det.title}} </span>       
                    <span class="description" *ngIf="det.description"> {{det.description}} </span>       
                </li>
            </ng-container>
        </ul>
        </ng-container>
    </div>
</div>