<div class="container">
  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="form">
        <mat-card>
          <div class="row">
            <form class="col" [formGroup]="form">
              <mat-card-content>
                <ng-container *ngFor="let key of ckeys">
                  <mat-card-title>{{key | formTitle: forms}}</mat-card-title>
                  <dynamic-form [data]="forms" [controlName]="key" (uploadEvent)="fileHandler($event)"></dynamic-form>
                </ng-container>
              </mat-card-content>
            </form>

            <div class="col row">
              <div class="col-1">
                <mat-icon class="semaphore" [ngClass]="[status.semaphore]">
                  {{(status.semaphore === 'green')? 'check_circle': (status.semaphore === 'orange')? 'pending': 'cancel'}}
                </mat-icon>
              </div>
              <form class="col form-printers" [formGroup]="printersForm">
                <mat-select class="select-mat printerselect" placeholder="cargando..." formControlName="printer">
                  <mat-select-trigger>
                    {{status.name}}
                  </mat-select-trigger>
                  <mat-option *ngFor="let print of printers" [value]="print.id">
                    {{print.name}}
                    <mat-icon class="semaphore" [ngClass]="[print.semaphore]">
                      {{(status.semaphore === 'green')? 'check_circle': (status.semaphore === 'orange')? 'pending': 'cancel'}}
                    </mat-icon>
                  </mat-option>
                </mat-select>
              </form>
            </div>
          </div>
          <mat-card-actions>
            <mat-spinner *ngIf="loading else showbuttons"></mat-spinner>
            <ng-template #showbuttons>
              <ng-container *ngFor="let but of buttons">
                <ng-container *ngIf="but.action === 'uploadFile' else getaction">
                  <button mat-flat-button color="primary" [disabled]="form.invalid"
                    (click)="upload(but.path)">{{but.label}}</button>
                </ng-container>
                <ng-template #getaction>
                  <button mat-flat-button color="primary" [disabled]="form.invalid"
                    (click)="dopost(but.path)">{{but.label}}</button>
                </ng-template>
              </ng-container>
            </ng-template>
          </mat-card-actions>
        </mat-card>
      </ng-container>
    </div>
  </div>

  <ng-container *ngIf="dataSource?.data?.length > 0">
    <div class="row">
      <div class="col-12">
        <mat-card>
          <mat-card-content>
            <div class="history-title">
              <mat-card-title>
                {{titleLoad}}
              </mat-card-title>
              <mat-card-subtitle>
                {{docMessage.label}}
              </mat-card-subtitle>
              <mat-card-subtitle>
                <button mat-flat-button color="primary" (click)="toggleChecks()" >
                  <mat-icon>
                    {{checkboxicon}}
                  </mat-icon> seleccionar
                </button>
              </mat-card-subtitle>
            </div>
            <div class="col-12">
              <ng-container *ngIf="dataSource?.data?.length > 0">
                <table class="ww-100" mat-table [dataSource]="dataSource" multiTemplateDataRows>
                  <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
                    <ng-container class="d-flex" [ngSwitch]="col">

                      <ng-container *ngSwitchCase="'selected'">
                        <th mat-header-cell *matHeaderCellDef>{{colDictionary[col]}}</th>
                        <td mat-cell *matCellDef="let row">
                          <mat-checkbox color="primary" [(ngModel)]="row[col]" [checked]="row[col].selected">
                          </mat-checkbox>
                        </td>
                      </ng-container>

                      <ng-container *ngSwitchCase="'amount'">
                        <th mat-header-cell *matHeaderCellDef>{{colDictionary[col]}}</th>
                        <td mat-cell *matCellDef="let row">
                          <mat-form-field appearance="fill" [style.width.px]=75>
                            <input matInput class="amount" type="number" color="primary" [(ngModel)]="row[col]" min="0"
                              (ngModelChange)="row[col] = $event || 0"
                              [ngClass]="{'mat-form-field-invalid': !!row[col]}">
                          </mat-form-field>
                        </td>
                      </ng-container>

                      <ng-container *ngSwitchDefault>
                        <th mat-header-cell *matHeaderCellDef>{{colDictionary[col]}}</th>
                        <td mat-cell class="text-color" *matCellDef="let row">{{row[col]}}</td>
                      </ng-container>

                    </ng-container>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="elementRow"></tr>

                </table>
              </ng-container>
            </div>
          </mat-card-content>
          <mat-card-footer>
            <mat-progress-bar *ngIf="disabledPrint" mode="indeterminate"></mat-progress-bar>
          </mat-card-footer>
          <mat-card-actions>
            <section>
              <span class="mat-h2">Total etiquetas a Imprimir {{getTotal}}</span>
            </section>
            <ng-container *ngFor="let but of loadbuttons">
              <button mat-flat-button color="primary" (click)="dopost(but.path)" [disabled]="disabledPrint">
                <mat-icon [matTooltip]="but.description">check_circle</mat-icon>
                {{but.label}}
              </button>
            </ng-container>

          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </ng-container>
</div>
