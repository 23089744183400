import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/services/config.service';
import { DataService } from 'src/app/services/data.service';
import { FormBaseComponent } from '../form-base/form-base.component';

@Component({
  selector: 'form-manual-manifest',
  templateUrl: './form-manual-manifest.component.html',
  styleUrls: ['./form-manual-manifest.component.scss']
})
export class FormManualManifestComponent extends FormBaseComponent implements OnInit {
  private _message: string;
  private _loading: boolean;
  storage: string;

  constructor(
    private configService: ConfigService,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public dataService: DataService,
    private snackbar: MatSnackBar
  ) {
    super(fb, dialog);
  }

  ngOnInit(): void {
    this._loading = false;
    this.configService.selectedStoreSubject
      .subscribe(storage => this.storage = storage.id);
    this.dataService.get(this.router.url)
      .subscribe(data => {
        this.config = data;
        this.getForm();
      });
  }

  doget(path) {
    this.dataService.get(path)
      .subscribe((data) => { console.log('===>🚀 data 🚀', data); });
  }

  get message(): string {
    return this._message;
  }

  get loading(): boolean {
    return this._loading;
  }

  get buttons(): any[] {
    if (!this.config?.body?.render?.forms?.some(f => !!f.actions[0].path)) {
      return [];
    }
    return this.config?.body?.render?.forms.map(f => f.actions).flat();
  }

  dopost(path) {
    const {storageBinWarehouse} = this.form.get('manifestBodyCreation').value
    const form = { ...this.form.get('manifestBodyCreation').value, storageBin: storageBinWarehouse,  storageBinWarehouse: this.storage }
    this.dataService.post(path, form)
      .subscribe(
        () => {
          this.snackbar.open('Manifest Creado');
          this.form.reset();
        },
        err => {
          this.snackbar.open('Error, Manifest no creado');
        }
      );
  }

  searchHandler({ path, stockCode }) {
    this.dataService.get(path, { stockCode, storageBinWarehouse: this.storage })
      .subscribe(
        data => {
          if (data?.body?.render?.message?.status === 'ERROR') {
            this.snackbar.open(data?.body?.render?.message?.label || 'Error, StockCode no encontrado');
          } else
            if (data?.body?.render?.forms[0]?.fields?.length > 0) {
              const fields = data?.body?.render?.forms[0].fields;
              fields.forEach(({ key, value }) => {
                if (key === 'storageBinWarehouse') {
                  value = (value || this.storage) + this.getLocationField(fields);
                }
                if(key !== 'storageBinLocation') {
                  this.form.get('manifestBodyCreation').get(key).patchValue(value);
                }
              });
            } else {
              this.snackbar.open('Error, StockCode no encontrado');
            }
        },
        err => {
          this.snackbar.open('Error, StockCode no encontrado');
        }
      );
  }

  private getLocationField(fields): string {
    let location = '';
    if (fields.length > 0) {
      const auxlocation = fields.find(field => field.key === 'storageBinLocation');
      location = auxlocation?.value || '';
    }
    return location;
  }

  private getForm() {
    const form = this.config?.body?.render?.forms;
    if (form) {
      this.forms = form;
    }
    this.values = {};
    this.setForm();
  }

}
