<section>
  <div class="contenedor">
    <div class="contenedor_img">
      <img src="/assets/load.png">
    </div>
    <div class="contenedor_formulario" *ngIf="show">
      <form [formGroup]="resetForm">
        <div>
          <span class="mat-headline">Ingresa tu nueva contraseña</span>
        </div>
        <span class="mat-h4">minimo 8 caracteres</span>
        <div class="row">
          <div class="col-12 col-md-12">
            <mat-form-field appearance="fill">
              <mat-label>Nueva Password:</mat-label>
              <input matInput placeholder="Ingresa tu nueva password" formControlName="newPassword"
                autocomplete="off" />
              <mat-error *ngIf="resetForm.get('newPassword').hasError('minlength')">
                Mínimo 8 caracteres
              </mat-error>
              <mat-error *ngIf="resetForm.get('newPassword').hasError('required')">
                Password <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-12">
            <mat-form-field appearance="fill">
              <mat-label>Reingresa tu clave</mat-label>
              <input matInput placeholder="Reingresa tu nueva password" formControlName="confirm" autocomplete="off" />
              <mat-error *ngIf="resetForm.get('confirm').hasError('confirmPasswordError')">
                La contraseña y la confirmación deben coincidir
              </mat-error>
              <mat-error *ngIf="resetForm.get('confirm').hasError('required')">
                Confirmacion de password es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-12">
            <button mat-stroked-button (click)="send()" [disabled]="resetForm.invalid">
              Resetear contraseña
            </button>
          </div>

        </div>
      </form>
    </div>
  </div>
</section>
