<div class="container-fluid">
  <div class="row">
    <header class="col-12 px-0" *ngIf="activeHeader"></header>
  </div>
  <div class="row">
    <sidebar *ngIf="activeSidebar" class="col-3 px-0 d-none d-sm-block"></sidebar>
    <div class="px-0 mx-0" [ngClass]="(activeSidebar)?'col-md-9 col-12':'col-12'">
      <div class="d-flex">
        <div class="col px-0">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
