import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { delay } from 'rxjs/operators';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'load-buttons',
  templateUrl: './load-buttons.component.html',
  styleUrls: ['./load-buttons.component.scss']
})
export class LoadButtonsComponent implements OnInit {
  @Input() buttons: any;
  @Output() handleRefresh: EventEmitter<any> = new EventEmitter();
  loading: boolean;
  syncing: boolean;
  constructor(
    private data: DataService
  ) { }

  ngOnInit(): void {
    this.loading = false;
  }

  sync() {
    if (this.syncing) return;
    this.syncing = true
    this.data.triggerLoad(this.buttons.imagesSync)
      .subscribe(
        res => {
          this.syncing = false;
          alert('Imágenes sincronizadas correctamente');
          console.log(res);
        },
        err => {
          this.syncing = false;
          alert('Por favor intenta más tarde');
          console.error(err);
        }
      );
  }

  go() {
    window.open(this.buttons.excel, '_blank');
  }

  trigger() {
    if (this.loading) return;
    this.loading = true;
    this.data.triggerLoad(this.buttons.load)
      .subscribe(
        res => {
          this.loading = false;
          this.handleRefresh.emit(res);
          console.log(res);
        },
        err => {
          this.loading = false;
          console.error(err);
        }
      )
  }

}
