<ng-container *ngIf="barChartData?.length > 0">
  <div style="display: block;">
    <canvas baseChart 
      [datasets]="barChartData"
      [labels]="barChartLabels"
      [options]="barChartOptions"
      [plugins]="barChartPlugins"
      [legend]="barChartLegend"
      [chartType]="barChartType">
    </canvas>
  </div>
</ng-container>

