import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

type DialogData = any;

@Component({
  selector: 'print-dialog',
  templateUrl: './print-dialog.component.html',
  styleUrls: ['./print-dialog.component.scss']
})
export class PrintDialogComponent implements OnInit {
  btnMsg: string;
  labelForm: FormGroup;
  options: string[];

  constructor(
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<PrintDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit(): void {
    this.options = ['', 'CAPITAL', 'NEW', 'DAMAGED', 'REPAIRED', 'SCRAP'];
    this.setForm();
  }
  setForm() {
    this.labelForm = this.fb.group({
      printAmount: [this.data.printAmount || 0, Validators.compose([
        Validators.required,
        Validators.pattern(/^-?(0|[1-9]\d*)?$/),
        Validators.min(1)
      ])],
    });

    if (this.data.origin !== 'ESCANER') {
      this.labelForm.addControl('storageBin',
        new FormControl(this.data.storageBinLocation,
          Validators.compose([
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5)
          ])
        )
      );
      this.labelForm.addControl('purchaseOrder',
        new FormControl({ value: this.data.purchaseOrder, disabled: !!this.data.purchaseOrder })
      );
      this.labelForm.addControl('valuationType',
        new FormControl(this.data.valuationType)
      );
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  get isNotScanner(): boolean {
    return this.data?.origin !== 'ESCANER'
  }

  get isScanner(): boolean {
    return this.data?.origin === 'ESCANER'
  }

  get dataDialog(): any {
    return { ...this.data, ...this.labelForm.value };
  }

}
