<section>
  <div class="contenedor">
    <div class="contenedor_img">
      <img src="/assets/load.png">
    </div>
    <div class="contenedor_formulario" *ngIf="show">
      <form [formGroup]="iforgotForm">
        <div>
          <span class="mat-headline">¿Olvidó su contraseña?</span>
        </div>
        <span class="mat-h4">ingrese su email para restaurarla</span>
        <div class="row">
          <div class="col-12 col-md-12">
            <mat-form-field appearance="fill">
              <mat-label>email</mat-label>
              <input matInput placeholder="ej. example@mail.com" formControlName="mail" autocomplete="off" />
              <mat-error *ngIf="iforgotForm.get('mail').hasError('email') && !iforgotForm.get('mail').hasError('required')">
                Ingrese un email valido
              </mat-error>
              <mat-error *ngIf="iforgotForm.get('mail').hasError('required')">
                email es <strong>requerido</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-12">
            <button mat-flat-button color="primary" (click)="send()" [disabled]="iforgotForm.invalid">
              Restaurar contraseña
            </button>
            <button class="ml-10" mat-flat-button color="primary" [routerLink]="'/login'">
              Volver
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
