import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './components/login/login.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AuthGuard } from './core/guards/auth.guard';
import { ListComponent } from './components/list/list.component';
import { HtmlCreatorComponent } from './components/html-creator/html-creator.component';
import { FormMasterComponent } from './components/form-master/form-master.component';
import { FormManifestComponent } from './components/form-manifest/form-manifest.component';
import { LabelStockComponent } from './components/label-stock/label-stock.component';
import { FormUserComponent } from './components/form-user/form-user.component';
import { FormManualManifestComponent } from './components/form-manual-manifest/form-manual-manifest.component';
import { IForgotPasswordComponent } from './components/i-forgot-password/i-forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { FormStorageBinComponent } from './components/form-storage-bin/form-storage-bin.component';
import { FormWarehouseComponent } from './components/form-warehouse/form-warehouse.component';
import { FormGrReceptionComponent } from './components/form-gr-reception/form-gr-reception.component';

const routes: Routes = [
  {
    path: 'login',
    pathMatch: 'full',
    component: LoginComponent,
    data: {
      activeSidebar: false,
      activeHeader: false
    }
  },
  {
    path: 'tools',
    pathMatch: 'full',
    component: HtmlCreatorComponent,
    data: {
      activeSidebar: true,
      activeHeader: true
    }
  },
  {
    path: 'dashboard',
    pathMatch: 'full',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: {
      activeSidebar: true,
      activeHeader: true
    }
  },
  {
    path: 'list/:key',
    pathMatch: 'full',
    component: ListComponent,
    canActivate: [AuthGuard],
    data: {
      activeSidebar: true,
      activeHeader: true,
      printedLabel: {
        key: 'captureMethod',
        value: 'REIMPRESION,ARCHIVO,MANUAL'
      },
    }
  },
  {
    path: 'load',
    canActivate: [AuthGuard],
    data: {
      activeSidebar: true,
      activeHeader: true
    },
    children: [
      {
        path: 'master',
        component: FormMasterComponent
      },
      {
        path: 'manifest',
        component: FormManifestComponent
      },
      {
        path: 'storagebin',
        component: FormStorageBinComponent
      },
      {
        path: 'warehouse',
        component: FormWarehouseComponent
      },
      {
        path: 'grReception',
        component: FormGrReceptionComponent
      }
    ]
  },
  {
    path: 'detail',
    canActivate: [AuthGuard],
    data: {
      activeSidebar: true,
      activeHeader: true
    },
    children: [
      {
        path: 'labelStock',
        component: LabelStockComponent
      },
      {
        path: 'user/:key',
        component: FormUserComponent
      }
    ]
  },
  {
    path: 'creation/manifest',
    canActivate: [AuthGuard],
    component: FormManualManifestComponent,
    data: {
      activeSidebar: true,
      activeHeader: true
    },
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'login'
  },
  {
    path: 'i-forgot-password',
    component: IForgotPasswordComponent,
  },
  {
    path: 'reset/:token/:mail',
    component: ResetPasswordComponent,
  },
  { path: 'just/dashboard', redirectTo: 'dashboard' },
  {
    path: '**', //component: DashboardComponent,
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
