import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FORM_KEY, SHADOW_INPUT, SHOWED_INPUT, UPLOAD_MANIFEST_CONTRACT } from 'src/app/config/uploadManifest.contract.constant';
import { ConfigService } from 'src/app/services/config.service';
import { DataService, IActions, IEntries } from 'src/app/services/data.service';
import { FileUploadService } from 'src/app/services/file-upload.service';
import { PrintersService } from 'src/app/services/printers.service';
import { FormBaseComponent } from '../form-base/form-base.component';


@Component({
  selector: 'form-gr-reception',
  templateUrl: './form-gr-reception.component.html',
  styleUrls: ['./form-gr-reception.component.scss']
})
export class FormGrReceptionComponent extends FormBaseComponent implements OnInit {
  progress: number;
  _message: string;
  file: File;
  _loading: boolean;

  displayedColumns: string[];
  data: IEntries[];
  dataSource: MatTableDataSource<IEntries>;
  colDictionary = {};
  actions: IActions[];
  headerLoad: any;
  titleLoad: string;
  docMessage: any;
  disabledPrint: boolean;
  printer$: Subscription = Subscription.EMPTY;
  printersForm: FormGroup;
  printers: any[];

  constructor(
    public fb: FormBuilder,
    public dialog: MatDialog,
    public router: Router,
    public dataService: DataService,
    private uploadService: FileUploadService,
    private printerStatus: PrintersService,
    private configService: ConfigService,
    private snackBar: MatSnackBar
  ) {
    super(fb, dialog);
  }

  ngOnInit(): void {
    this._loading = false;
    this.dataService.get(this.router.url)
      .subscribe(data => {
        this.config = data;
        this.getForm();
      });
    this.setPrinters();
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    if (!this.printer$.closed) {
      this.printer$.unsubscribe();
    }
  }

  fileHandler(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get(FORM_KEY).patchValue({
        [SHADOW_INPUT]: file
      });
    }
  }

  doget(path) {
    this.dataService.get(path)
      .subscribe(
        () => {
          this.snackBar.open('Carga Realizada');
          this.fullreset();
        });
  }

  dopost(path) {
    if (this.getTotal <= 0) {
      this.snackBar.open('Error no puede imprimir 0 etiquetas');
      return;
    }
    if (!this.status.id) {
      this.snackBar.open('No hay una impresora seleccionada o disponible');
      return;
    }
    if (!this.status.status.ready) {
      this.snackBar.open('La impresora seleccionada no esta disponible');
      return;
    }
    this.disabledPrint = true;
    let toPrint = this.dataSource.data.filter(row => row.selected && row.amount > 0) as any;

    toPrint = { toPrint, printer: this.status.id };
    this.dataService.post(path, toPrint)
      .subscribe(
        (data) => {
          const { message } = data.body.render
          this.snackBar.open(message?.label || 'Impresion enviada');
          this.fullreset();
          this.disabledPrint = false;
        },
        (err) => {
          this.snackBar.open('Hubo un problema, Intente nuevamente más tarde');
          this.disabledPrint = false;
        });
  }

  get status(): any {
    let status = {
      semaphore: 'red',
      name: 'cargando...',
    }
    if (this.printers?.length > 0) {
      const printer = this.printers.find(p => p.id === this.printersForm.get('printer').value);
      if (printer) {
        status = printer
      }
    }
    return status;
  }

  setPrinters(): void {
    this.printersForm = this.fb.group({
      printer: ['', Validators.compose([
        Validators.required
      ])]
    });
    this.printer$ = this.printerStatus
      .printerStatu$
      .subscribe((status: any[]) => {
        if (status?.length > 0) {
          let prev = this.printersForm.get('printer').value || '';
          this.printers = [];
          status
            .map(p => {
              this.printers.push(p);
            });
          if (prev === '') {
            prev = this.printers[0].id;
          }
          this.printersForm.get('printer').patchValue(prev, { emitEvent: false });
        }
      });
  }

  fullreset() {
    this.form.reset();
    this.dataSource = null;
    this.displayedColumns = null;
    this.actions = null;
    this.headerLoad = null
    this.titleLoad = null;
  }

  upload(path): void {
    this.progress = 0;
    this._message = '';
    this._loading = true;
    if (this.form.valid) {
      const file: File = this.form.get(FORM_KEY).get(SHADOW_INPUT).value;

      console.log('===>🚀 this.form.get(FORM_KEY).value 🚀', this.form.get(FORM_KEY).value);
      const formData: FormData = new FormData();
      formData.append('file', file);
      formData.append('storagebin', this.configService.selectedStorage.id);
      Object.entries(this.form.get(FORM_KEY).value)
        .filter(([key]) => ![SHADOW_INPUT, SHOWED_INPUT].includes(key))
        .forEach(([key, value]) => {
          formData.append(key, value as any);
        });
      this.uploadService
        .upload(path, formData)
        .subscribe(
          (event: any) => {
            if (event.type === HttpEventType.UploadProgress) {
              this.progress = Math.round(100 * event.loaded / event.total);
            } else if (event instanceof HttpResponse) {
              this.progress = undefined;
              this._message = event.body.message;
              console.log('===>🚀 event 🚀', event);
              this.form.reset();
              this._loading = false;

              this.showProcess(event);
            }
          },
          (err: any) => {
            console.log(err);
            this.progress = 0;
            if (err.error && err.error.message) {
              this._message = err.error.message;
            } else {
              this._message = 'No se pudo cargar el archivo!';
            }
            this._loading = false;
          });
    }
  }

  get loading(): boolean {
    return this._loading;
  }

  get message(): string {
    return this._message;
  }

  get buttons(): any[] {
    if (!this.config?.header?.render?.forms?.some(f => !!f.actions[0].path)) {
      return [];
    }
    return this.config?.header?.render?.forms.map(f => f.actions).flat();
  }

  get loadbuttons(): any[] {
    return this.actions;
  }

  get getTotal(): number {
    const total: number = this.dataSource?.data?.reduce((acc, row) => {
      acc += (row.selected) ? (row.amount || 0) : 0;
      return acc;
    }, 0);
    return total;
  }

  get checkboxicon(): string {
    let icon = 'check_box'
    return icon;
  }

  toggleChecks(): void {
    const [{selected}] = this.dataSource.data;
    this.dataSource.data = this.dataSource.data.map(row => {
      row.selected = !selected;
      return row;
    }) as any;
  }

  private getForm() {
    const form = this.config?.header?.render?.forms;
    if (form) {
      this.forms = form;
    }
    this.values = {};
    this.setForm();
  }

  private showProcess(event) {
    this.dataSource = new MatTableDataSource();

    const { entries, render: { columns, actions = [], header, title, message } } = event.body.body;

    this.displayedColumns = columns.map(col => {
      this.colDictionary[col.key] = col.title;
      return col.key
    });
    if (actions.length > 0) {
      this.actions = actions;
    }
    this.docMessage = message;
    this.dataSource.data = entries;
    this.headerLoad = header;
    this.titleLoad = title;
  }
}
