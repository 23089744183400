import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { BehaviorSubject } from 'rxjs';
import { GET_PRINTERS_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrintersService {
  printerStatu$: BehaviorSubject<any>;
  data: any[];

  constructor(
    private socket: Socket,
    private http: HttpClient,
  ) {
    this.data = [];
    this.printerStatu$ = new BehaviorSubject(undefined);
    this.http.get(GET_PRINTERS_URL)
      .subscribe((prints: any[]) => {
        if (prints?.length > 0) {
          prints = this.status(prints);
          this.saveArr(prints);
          this.printerStatu$.next(this.data);
        }
      })

    this.socket.fromEvent('status')
      .subscribe(
        (printer: any) => {
          const [aStatus] = this.status([printer])
          this.update(aStatus);
          this.printerStatu$.next(this.data)
        },
        err => this.printerStatu$.error(err),
        () => this.printerStatu$.complete()
      )
  }

  status(printers) {
    if (!printers) {
      return [{ semaphore: 'red' }];
    }
    if (printers?.length > 0) {
      printers = printers.map(printer => {
        let semaphore = 'red';
        const { ready, producer } = printer.status;
        if (ready && producer === 'ONLINE') {
          semaphore = 'green';
        }
        else if (producer === 'ONLINE') {
          semaphore = 'orange';
        }
        return { ...printer, semaphore };
      })
    }
    return printers;
  }

  update(print) {
    console.log('===>🚀 print 🚀', print);
    this.data = this.data.map(p =>  {
      if(p.id === print.id){
        return print;
      }
      return p;
    })
  }

  saveArr(prints: any[]) {
    this.data = prints.map(p => p);
  }
}
