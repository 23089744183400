export const SHOWED_INPUT = 'file';
export const SHADOW_INPUT = 'fileShadow';
export const FORM_KEY = 'masterHeadLoad';

export const UPLOAD_MASTER_CONTRACT = [
  {
    key: FORM_KEY,
    title: 'Formulario Carga de Maestro',
    fields: [
      {
        key: SHOWED_INPUT,
        label: 'Archivo Maestro',
        type: 'file',
        required: true
      },
      {
        key: SHADOW_INPUT,
        label: '',
        type: 'notInput',
        required: true
      }
    ]
  }
] as any;
export const action = {

}